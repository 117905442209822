<template>
	<div v-if="loaded" class="c-loan-calculator js-loan-calculator">

		<div class="c-loan-calculator__continue" v-if="state.canContinue && state.loanAppUrl">
			<button class="c-loan-calculator__continue-close" aria-label="Close" @click="actions.hideContinue()"></button>
			<p v-if="config.ApplicationResumeStatement" class="c-loan-calculator__continue-text">{{config.ApplicationResumeStatement}}</p>
			<a :href="state.loanAppUrl" class="o-btn o-btn--small o-btn--nowrap o-btn--supporting c-loan-calculator__continue-button">Continue my application</a>
		</div>

		<a :href="state.loanAppUrl" v-if="state.canContinue && state.loanAppUrl" class="o-btn o-btn--small o-btn--nowrap o-btn--supporting c-loan-calculator__continue-mobile">Continue my application</a>

		<div class="c-loan-calculator__panel">
			<span class="c-loan-calculator__title-label t-text">Loan Calculator</span>
			<h2 class="c-loan-calculator__title t-pseudo-border">How much would you like to borrow?</h2>

			<form class="c-form c-loan-calculator__form">
				<div class="o-grid">
					<div class="o-grid__item">
						<teraform-range :input="inputs.LoanAmount" class="c-loan-calculator__range" />
					</div>
					<div class="o-grid__item">
						<teraform-range :input="inputs.LoanTerm" class="c-loan-calculator__range" />
					</div>
				</div>

				<div v-if="state.error">
					<p class="c-loan-calculator__error">Sorry, there has been a problem.</p>
				</div>
				<div v-if="!state.error" class="c-loan-calculator__results">
					<dl class="c-loan-calculator__payment-list">
						<div class="c-loan-calculator__payment-secondary">
							<div class="c-loan-calculator__payment">
								<dt class="c-loan-calculator__payment-frequency">Monthly</dt>
								<dd class="c-loan-calculator__payment-amount">
									<span class="c-loan-calculator__payment-unit">$</span><span class="c-loan-calculator__payment-amount-text">{{state.repaymentM}}</span>
								</dd>
							</div>
							<div class="c-loan-calculator__payment">
								<dt class="c-loan-calculator__payment-frequency">Fortnightly</dt>
								<dd class="c-loan-calculator__payment-amount">
									<span class="c-loan-calculator__payment-unit">$</span><span class="c-loan-calculator__payment-amount-text">{{state.repaymentF}}</span>
								</dd>
							</div>
						</div>
						<div class="c-loan-calculator__payment-primary t-text">
							<div class="c-loan-calculator__payment">
								<dt class="c-loan-calculator__payment-frequency">Weekly</dt>
								<dd class="c-loan-calculator__payment-amount">
									<span class="c-loan-calculator__payment-unit">$</span><span class="c-loan-calculator__payment-amount-text">{{state.repaymentW}}</span>
								</dd>
							</div>
						</div>
					</dl>

					<p class="c-loan-calculator__total">
						<span class="c-loan-calculator__total-text">Total repayable based on weekly instalments.</span>
						<span class="c-loan-calculator__total-amount t-text"><span class="c-loan-calculator__total-amount-unit">$</span><span class="c-loan-calculator__total-amount-text">{{state.repaymentTotalW}}</span></span>
					</p>
				</div>
			</form>

			<div class="c-loan-calculator__apply-wrapper">
				<a :href="state.newAppUrl" v-if="state.newAppUrl" @click="actions.prepareForNewApplication()" class="o-btn o-btn--supporting o-btn--nowrap c-loan-calculator__apply">Apply now</a>
			</div>
		</div>
		<div v-if="config.CalculatorDisclaimer" class="c-loan-calculator__fine-print-wrapper">
			<p class="c-loan-calculator__fine-print">{{config.CalculatorDisclaimer}}</p>
			<a v-if="config.RatesLink" :href="config.RatesLink.Url" :target="config.RatesLink.Target" class="o-btn o-btn--small o-btn--bordered o-btn--outline c-loan-calculator__fine-print-button">{{config.RatesLink.Name}}</a>
		</div>
	</div>
</template>
<script>
	import { Teraform } from 'Vue/teraform/teraform';

	let setup = function ($scope, config) {

		let inputs = {
			LoanAmount: {
				label: 'Amount',
				value: config.DefaultAmount,
				disabled: function () {
					return false;
				}
			},
			LoanTerm: {
				label: 'Term',
				value: config.DefaultTerm,
				disabled: function () {
					return false;
				}
			}
		};

		//console.log({
		//	method: 'setup',
		//	$scope
		//});

		let localStorageSupport = !!window.localStorage && ('setItem' in window.localStorage);

		let toDollarString = function (number) {
			var options = {
				// style: 'currency',
				// currency: 'NZD'
				style: 'decimal',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			};

			var dollarString = number.toLocaleString('en-nz', options);

			return dollarString;
		};

		let createDollarValues = function (el) {
			var obj = {
				displayValue: '$' + el.toLocaleString('en-nz'),
				value: el
			};

			return obj;
		};

		let createMonthValues = function (el) {
			var obj = {
				displayValue: el + ' month' + (el !== 1 ? 's' : ''),
				value: el
			};

			return obj;
		};

		let logic = {
			calculate: function () {
				// Using shorthand suffixes:
				// W: Weekly
				// F: Fortnightly
				// M: Monthly

				var PerYear = {
					WEEKS: 52,
					FORTNIGHTS: 26,
					MONTHS: 12
				};

				var interestAnnual = config.InterestPercent / 100;
				var loanAmount = $scope.inputs?.LoanAmount?.value || config.DefaultAmount;
				var loanTerm = $scope.inputs?.LoanTerm?.value || config.DefaultTerm; // Months

				$scope.state = $scope.state || {};

				try {
					var establishmentFee = logic.getEstablishmentFee();

					var principal = loanAmount + establishmentFee;

					var numberOfPaymentsW = loanTerm / PerYear.MONTHS * PerYear.WEEKS;
					var numberOfPaymentsF = loanTerm / PerYear.MONTHS * PerYear.FORTNIGHTS;
					if (loanTerm === 3) {
						// Special rule: 3 month terms have 6 fortnights, instead of 6 1/2
						numberOfPaymentsF = 6;
					}
					var numberOfPaymentsM = loanTerm;

					var interestPerPaymentW = interestAnnual / PerYear.WEEKS;
					var interestPerPaymentF = interestAnnual / PerYear.FORTNIGHTS;
					var interestPerPaymentM = interestAnnual / PerYear.MONTHS;

					var interestPowerCalcW = Math.pow(1 + interestPerPaymentW, -numberOfPaymentsW);
					var interestPowerCalcF = Math.pow(1 + interestPerPaymentF, -numberOfPaymentsF);
					var interestPowerCalcM = Math.pow(1 + interestPerPaymentM, -numberOfPaymentsM);

					// Payment amounts are rounded to the nearest cent between calculating interest and adding the per payment fee
					var paymentAmountW = parseFloat((principal * interestPerPaymentW / (1 - interestPowerCalcW)).toFixed(2)) + config.PerPaymentFee;
					var paymentAmountF = parseFloat((principal * interestPerPaymentF / (1 - interestPowerCalcF)).toFixed(2)) + config.PerPaymentFee;
					var paymentAmountM = parseFloat((principal * interestPerPaymentM / (1 - interestPowerCalcM)).toFixed(2)) + config.PerPaymentFee;

					var paymentTotalW = paymentAmountW * numberOfPaymentsW;
					var paymentTotalF = paymentAmountF * numberOfPaymentsF;
					var paymentTotalM = paymentAmountM * numberOfPaymentsM;

					$scope.state.repaymentW = toDollarString(paymentAmountW);
					$scope.state.repaymentF = toDollarString(paymentAmountF);
					$scope.state.repaymentM = toDollarString(paymentAmountM);

					$scope.state.repaymentTotalW = toDollarString(paymentTotalW);
					$scope.state.repaymentTotalF = toDollarString(paymentTotalF);
					$scope.state.repaymentTotalM = toDollarString(paymentTotalM);

					$scope.state.error = false;
				} catch (e) {
					console.error(e);
					$scope.state.error = true;
				}

				//console.log({
				//	method: 'logic.calculate',
				//	loanAmount,
				//	loanTerm,
				//	repaymentW: $scope.state.repaymentW,
				//	repaymentF: $scope.state.repaymentF,
				//	repaymentM: $scope.state.repaymentM,
				//});

				return $scope.state;
			},

			getEstablishmentFee: function () {
				var loanAmount = $scope.inputs?.LoanAmount?.value || config.DefaultAmount;
				var establishmentFeeRange;
				var establishmentFee;

				establishmentFeeRange = config.EstablishmentFeeRanges.filter(function (range) {
					return range.Start <= loanAmount && range.End >= loanAmount;
				});

				if (establishmentFeeRange.length !== 0) {
					establishmentFee = establishmentFeeRange[0].Amount;
				} else {
					//console.error({
					//	loanAmount,
					//	EstablishmentFeeRanges: config.EstablishmentFeeRanges,
					//	establishmentFeeRange,
					//	establishmentFee,
					//});
					throw new Error('Calculator: Could not find establishment fee range for selected loan amount');
				}

				return establishmentFee;
			},

			hideContinue: function () {
				$scope.state.canContinue = false;
			},

			prepareForNewApplication: function () {
				if (localStorageSupport) {
					localStorage.setItem('calculator-amount', $scope.inputs.LoanAmount.value);
				}
			}
		};

		let initialState = logic.calculate();

		initialState.canContinue = config.CanContinue;
		initialState.loanAppUrl = config.LoanAppUrl;
		initialState.newAppUrl = config.NewAppUrl;


		// view bindings
		var bindings = {
			handleSubmit: logic.handleSubmit,
			hideContinue: logic.hideContinue,
			prepareForNewApplication: logic.prepareForNewApplication
		};

		$scope.inputs = Teraform.mergeFieldConfig(config.inputs, Teraform.initInputs(inputs));
		$scope.inputs.LoanAmount.values = config.AmountRanges.map(createDollarValues);
		$scope.inputs.LoanAmount.update = logic.calculate;

		$scope.inputs.LoanTerm.values = config.TermRanges.map(createMonthValues);
		$scope.inputs.LoanTerm.update = logic.calculate;

		Teraform.form.init({
			// Required
			$scope: $scope,
			inputs: inputs,

			// Optional
			actions: bindings,
			state: initialState
		});

		$scope.loaded = true;
	}

	let app = {
		name: 'teraform-loan-calculator',
		props: ['config'],
		data() {
			return {
				loaded: false,
				inputs: null,
				state: null,
				actions: null,
			}
		},
		mounted() {
			setup(this, this.config);
			//console.log({
			//	method:'mounted()',
			//	task: 'teraform-loan-payment',
			//	$scope: this,
			//});
		},
		methods: {}
	};

	export default app;
</script>