/* app/ui/map/map.control */

import $ from 'jquery';
import { subscribe } from 'Util/pubsub';

var $maps;
var maps = [];

var MapControl = {
	init: function($elms) {
		$maps = $elms.filter('[data-map-type="control"]');

		MapControl._initSubscriptions();
	},

	_initSubscriptions: function() {
		subscribe('/map/apiLoaded', MapControl._processMaps);
	},

	_processMaps: function() {

		for (var i = 0; i < $maps.length; i++) {
			var mapCanvas = $maps[i];
			var $thisMap = $maps.eq(i);
			var latitude = $thisMap.attr('data-lat');
			var longitude = $thisMap.attr('data-lng');
			var zoom = parseInt($thisMap.attr('data-zoom'), 10);
			var myLocation = { latitude: parseFloat(latitude), longitude: parseFloat(longitude) };
			var latLng = new google.maps.LatLng(myLocation.latitude, myLocation.longitude);
			var branchLocation = $thisMap.attr('data-location');

			if (typeof branchLocation === 'undefined') {
				branchLocation = latitude + ',' + longitude;
			}

			var mapOptions = {
				zoom: zoom || 12,
				center: latLng,
				draggable: !matchMedia('(hover: none)').matches,
				streetViewControl: false,
				scrollwheel: false,
				mapTypeControl: true,
				panControl: false,
				panControlOptions: {
					position: google.maps.ControlPosition.LEFT_CENTER
				},
				zoomControl: true,
				zoomControlOptions: {
					style: google.maps.ZoomControlStyle.LARGE,
					position: google.maps.ControlPosition.RIGHT_BOTTOM
				}
			};

			var map = new google.maps.Map(mapCanvas, mapOptions);

			var marker = new google.maps.Marker({
				position: latLng,
				map: map,
				icon: '/content/images/interface/map/marker-large.png'
			});

			marker.addListener('click', function() {
				var url = 'https://www.google.com/maps/dir/?api=1&destination=' + branchLocation;
				window.open(url, '_blank');
			});

			var mapData = {
				map: map,
				options: mapOptions,
				location: myLocation
			};

			maps.push(mapData);
		}
	}
};

export { MapControl };
