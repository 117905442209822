/* app/ui/map/map.load */

import $ from 'jquery';
import { MapControl } from 'App/map/map.control';
import { MapInteractive } from 'App/map/map.interactive';
import { MapSticky } from 'App/map/map.sticky';
import { BranchFilter } from 'App/map/branch-filter';
import { FindABranch } from 'App/map/find-a-branch';
import { publish } from 'Util/pubsub';

// We use the 'Whitesite' account in the Terabyte Stats Google account Developer console
// This needs to be updated to a client account attached to their billing account before go-live
var apiKey = 'AIzaSyDG4jVOC8BJ2jRRbw3ck0Qpdj_cirRKYfc';
var apiLoaded = false;
var google;
var $maps;
var $findABranch;

var selectors = {
	map: '.js-map',
	findABranch: '.js-find-a-branch'
};

var MapLoad = {
	init: function() {
		$maps = $(selectors.map);
		$findABranch = $(selectors.findABranch);

		var $firstMap = $maps.eq(0);

		// If only interactive map is on page initialise the module for this
		// The MapInteractive module will only load the API code if the map is toggled to display
		// Otherwise load API and init MapControl module
		if ($maps.length || $findABranch.length) {
			if ($maps.length === 1 && $firstMap.attr('data-map-type') === 'interactive') {
				MapInteractive.init($firstMap);
				BranchFilter.init();
				MapSticky.init();
			} else {
				MapControl.init($maps);
			}

			if ($findABranch.length) {
				FindABranch.init();
			}
			MapLoad.loadApi();
		}
	},

	// Load Google Maps API version 3
	loadApi: function() {
		$.getScript('https://www.google.com/jsapi', function() {
			window.google.load('maps', '3', {
				other_params: '&key=' + apiKey + '&libraries=places,geometry',
				callback: MapLoad.mapApiLoaded
			});
		});
	},

	// Callback function once API is loaded
	mapApiLoaded: function() {
		apiLoaded = true;
		google = window.google;

		publish('/map/apiLoaded');
	},

	// Utility function to return API status and google object
	getMapApiStatus: function() {
		return {
			loaded: apiLoaded,
			google: google
		};
	}
};

export { MapLoad };
