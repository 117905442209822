/* PromiseLike v1.0.0 */

// Implement a "PromiseLike" interface, for Promise-like behaviour with a .then method

var PromiseLike = function (handler) {
	/** @type {Function[]} */
	this.callbacksResolve = [];
	/** @type {Function[]} */
	this.callbacksReject = [];
	/** @type {Function[]} */
	this.callbacksFinally = [];

	/** @type {any} */
	this.resolved = null;
	/** @type {any} */
	this.rejected = null;

	/**
	 * Execute all "resolve" callbacks, then all "finally" callbacks
	 *
	 * @param {any} data
	 *
	 * @return {void}
	 */
	var resolve = (function (data) {
		if (this.resolved || this.rejected) {
			return;
		}

		/** @type {number} */
		var i;
		/** @type {Function} */
		var callback;

		var callbacks = this.callbacksResolve.concat(this.callbacksFinally);

		for (i = 0; i < callbacks.length; i++) {
			callback = callbacks[i];

			callback(data);
		}
		this.resolved = data;
	}).bind(this);

	/**
	 * Execute all "reject" callbacks, then all "finally" callbacks
	 *
	 * @param {any} data
	 *
	 * @return {void}
	 */
	var reject = (function (data) {
		if (this.rejected || this.rejected) {
			return;
		}

		/** @type {number} */
		var i;
		/** @type {Function} */
		var callback;

		var callbacks = this.callbacksReject.concat(this.callbacksFinally);

		for (i = 0; i < callbacks.length; i++) {
			callback = callbacks[i];

			callback(data);
		}
		this.rejected = data;
	}).bind(this);

	handler(resolve, reject);
};

/**
 * Bind a callback to fire when the PromiseLike is resolved.
 *
 * @param {Function} callback
 *
 * @return {PromiseLike}
 */
PromiseLike.prototype.then = function (callback) {
	if (this.resolved) {
		callback(this.resolved);
	} else {
		this.callbacksResolve.push(callback);
	}

	return this;
};

/**
 * Bind a callback to fire when the PromiseLike is rejected.
 *
 * @param {Function} callback
 *
 * @return {PromiseLike}
 */
PromiseLike.prototype.catch = function (callback) {
	if (this.rejected) {
		callback(this.rejected);
	} else {
		this.callbacksReject.push(callback);
	}

	return this;
};

/**
 * Bind a callback to fire after the PromiseLike is resolved or rejected.
 *
 * @param {Function} callback
 *
 * @return {PromiseLike}
 */
PromiseLike.prototype.finally = function (callback) {
	if (this.resolved) {
		callback(this.resolved);
	} else if (this.rejected) {
		callback(this.rejected);
	} else {
		this.callbacksFinally.push(callback);
	}

	return this;
};

export { PromiseLike };