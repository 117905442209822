<template>
	<div v-show="input.condition()">
		<label class="c-form__label" :for="input.htmlId" v-if="input.hasLabel()">{{input.label}}<span class="c-form__required-optional" v-if="!(input.validation.required || input.hasCustomRequiredValidation)">&nbsp;(optional)</span></label>
		<label class="c-form__label" :for="input.htmlId" v-if="!input.hasLabel() && !input.validation.required"><span class="c-form__required-optional">(optional)</span></label>
		<textarea :id="input.htmlId"
				  :name="input.htmlName"
				  :placeholder="input.placeholder"
				  class="c-form__input"
				  :data-required="input.validation.required"
				  :maxlength="input.validation.maxlength"
				  :class="{'has-error' : input.error}"
				  :disabled="input.disabled()"
				  v-model="input.value"
				  @change="input.update()"
				  :aria-describedby="input.ariaDescribedby"></textarea>

		<div class="c-form__countdown" v-if="input.validation.maxlength">
			<span class="c-form__countdown-remaining">{{input.validation.maxlength - input.value.length}}</span>
		</div>

		<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
		<span class="c-form__error" v-if="input.errors.errorPattern" v-show="input.error === 'pattern'">{{input.errors.errorPattern}}</span>
		<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>
	</div>
</template>
<script>
	let setup = function ($input) {
		var maxlength;

		// JS config can override 'maxlength'
		if (typeof $input.validation.maxlength !== 'undefined') {
			maxlength = $input.validation.maxlength;
		} else {
			maxlength = $input.maxlength;
		}

		if (typeof maxlength !== 'undefined') {
			// Ensure 'maxlength' is an integer
			maxlength = parseInt(maxlength, 10);

			if (isNaN(maxlength)) {
				throw new Error('Teraform: invalid \'maxlength\' value');
			} else {
				$input.maxlength = maxlength;
				$input.validation.maxlength = maxlength;

				if ($input.value && $input.value.length > maxlength) {
					$input.value = $input.value.substring(0, maxlength);
				}
			}
		}
	};

	export default {
		name: 'teraform-countdown',
		props: ['input'],
		data() {
			return {
				loaded: false
			}
		},
		beforeMount() {
			if (this.input.value === null) {
				this.input.value = '';
			}
			setup(this.input);
		},
		mounted() {
			this.loaded = true;
			//console.log('teraform-countdown', this.input);
		},
		methods: {
		}
	};
</script>