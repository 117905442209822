/* app/ui/map/find-a-branch */

// This file is for controlling the "Find a Branch" control,
// not the map and listing component on the "Find a Branch" template

import $ from 'jquery';
import { subscribe } from 'Util/pubsub';

var $addressInput;
var googAutocomplete;
var branchData;

var FindABranch = {
	init: function() {
		FindABranch = this;
		FindABranch._initElements();
		FindABranch._initSubscriptions();
	},

	_initElements: function() {
		$addressInput = $('.js-find-a-branch-autocomplete');
		branchData = $addressInput.data('branches');
	},

	_initSubscriptions: function() {
		subscribe('/map/apiLoaded', FindABranch._initGoogleAddressSearch);
	},

	_initGoogleAddressSearch: function() {
		if ($addressInput.length) {
			$addressInput.focus(function() {
				$(this).val('');
			});
			googAutocomplete = new google.maps.places.Autocomplete(
				$addressInput[0],
				{ componentRestrictions: { country: 'nz' } }
			);

			setTimeout(function() {
					$addressInput.prop('autocomplete', 'false');
				}, 1000);

			googAutocomplete.addListener('place_changed', function() {
					var place = googAutocomplete.getPlace();
					if (!place.geometry) {
						// User entered the name of a Place that was not suggested
						return;
					}
					if (place.geometry.location) {
						FindABranch._findClosestBranch(place.geometry.location);
					}
				});
		}
	},

	_findClosestBranch: function(inputLocation) {
		var nearestBranch = null;
		var nearestDistance = 99999999;
		branchData.forEach(function(branchItem) {
			var branchItemLatLng = new google.maps.LatLng(branchItem.coordinates.latitude, branchItem.coordinates.longitude);
			var distance = FindABranch._calcDistance(inputLocation, branchItemLatLng);
			if (distance < nearestDistance) {
				nearestBranch = branchItem;
				nearestDistance = distance;
			}
		});
		if (nearestBranch) {
			window.location.href = nearestBranch.url;
		}
	},

	_calcDistance: function(p1, p2) {
		return google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000;
	}

};

export { FindABranch };