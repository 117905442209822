/* app/ui/nav/small */

import $ from 'jquery';
import 'Node/jquery-scroll-lock';
import { publish, subscribe, unsubscribe } from 'Util/pubsub';

var NavSmall;
var $nav;
var classes;
var selectors;

var $navItems;
var $navLinks;
var $navToggle;
var $navOverlay;
var elementsInit = false;

var NavSmall = {
	init: function($elm, selectorList, classList) {
		NavSmall = this;
		$nav = $elm;
		selectors = selectorList;
		classes = classList;

		NavSmall._initElements();
		NavSmall._initSubscriptions();
		NavSmall._initEvents();
	},

	_initElements: function() {
		if (!elementsInit) {
			$navItems = $nav.find(selectors.navItem);
			$navLinks = $nav.find('.js-megamenu__heading');
			$navToggle = $('.js-header__nav-toggle');
			$navOverlay = $('.js-navigation__overlay');
			elementsInit = true;
		}
	},

	_initSubscriptions: function() {
		subscribe('/nav/close', NavSmall._closeNav);
	},

	_initEvents: function() {
		$navToggle.on('click', NavSmall._toggleSmallMenu);
		$navLinks.on('click', NavSmall._openSubMenu);
		$navOverlay.on('click', NavSmall._toggleSmallMenu);
	},

	// close primary nav menu if open
	_closeNav: function() {
		if ($navToggle.attr('aria-expanded') === 'true') {
			$navToggle.first().trigger('click');
		}
	},

	// publish nav toggle event
	// show/hide primary nav menu dependant on current state
	// toggle class on menu button
	_toggleSmallMenu: function(event) {
		event.preventDefault();
		publish('/nav/navToggle');
		if ($nav.attr('aria-expanded') === 'true') {
			$nav.attr('aria-expanded', false);
			$navOverlay.attr('aria-expanded', false);
		} else {
			$nav.attr('aria-expanded', true);
			$navOverlay.attr('aria-expanded', true);
		}
		if ($navToggle.attr('aria-expanded') === 'true') {
			$navToggle.attr('aria-expanded', false);
			$('body').removeClass('nav-open');
			NavSmall._lockScroll('disable');
		} else {
			$navToggle.attr('aria-expanded', true);
			$('body').addClass('nav-open');
			if ($('body').width() > 1000) {
				NavSmall._lockScroll('enable');
			}

		}

		var title = $navToggle.attr('title');
		var altTitle = $navToggle.attr('data-alt-title');
		$navToggle.attr('title', altTitle);
		$navToggle.attr('data-alt-title', title);

		if ($nav.attr('aria-expanded') === 'false') {
			$nav.find('[aria-expanded="true"]').attr('aria-expanded', false);
		}
	},

	_openSubMenu: function(event) {
		event.preventDefault();
		var $link = $(this);
		var $icon = $link.find(selectors.navIcon);
		var $item = $link.closest(selectors.navItem);

		if ($item.attr('aria-expanded') !== 'true') {
			$nav.find(selectors.navItem).attr('aria-expanded', false);
		}
		if ($item.attr('aria-expanded') === 'true') {
			$item.attr('aria-expanded', false);
		} else {
			$item.attr('aria-expanded', true);
		}
	},

	// collapse nav and any expanded children
	_resetMenu: function() {
		$nav.attr('aria-expanded', false);
		$navItems.attr('aria-expanded', false);
		$navToggle.attr('aria-expanded', false);
	},

	//lock scroll when nav open under >=1024 screen width
	_lockScroll: function(status) {
		$('.o-layout-page').scrollLock(status);
		$('.js-header').scrollLock(status);
	}
};

export { NavSmall };
