<template>
<div style="padding: 20px; text-align:center; background: green; color: lightblue; font-weight: 900;">
	This is an example VUE child component
</div>
</template>
<script>
	//import { ref } from 'vue';
	//let setText = ref(text)
	export default {
		name: 'test-component-child',
		data() {
			return {
			}
		},
		mounted() {
		},
		methods: {
		}
	};
</script>