<template>
	<div v-if="text" class="c-tooltip c-tooltip--left js-tooltip @hiddenClass" :class="class" aria-live="polite">
		
		<button v-if="trigger" type="button" class="o-btn--link-bold c-tooltip__trigger--link js-tooltip__trigger">{{trigger}}</button>
		<button v-if="!trigger" type="button" class="c-tooltip__trigger t-btn js-tooltip__trigger" aria-label="Show help"></button>

		<div class="c-tooltip__body t-bg js-tooltip__body">
			<button type="button" class="c-tooltip__close t-bg__text js-tooltip__close" aria-label="Close"></button>
			<div class="c-tooltip__text t-bg__text" v-if="text" v-html="text" />

			<div v-if="image" class="c-tooltip__image">
				<img :src="image" />
				<!--@await Html.DxpImage(Model.Image, CropType.Original)-->
			</div>

			<button type="button" class="o-btn o-btn--small o-btn--bordered o-btn--outline c-tooltip__hide js-tooltip__close">Hide</button>
		</div>
	</div>
</template>
<script>
	let app = {
		name: 'teraform-tooltip',
		props: [
			'text',
			'trigger',
			'image',
			'class'
		],
		data() {
			return {}
		},
		mounted() {
			// console.log({ this: this });
		},
		methods: {}
	};
	export default app;
</script>