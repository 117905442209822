/* app/ui/rotator/rotator */

import $ from 'jquery';
import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fade';

var $rotators;

var selectors = {
	wrapper: '.js-rotator__wrapper',
	rotator: '.js-rotator',

	prev: '.js-rotator__prev',
	next: '.js-rotator__next'
};

var dataSelectors = {
	flickity: 'flickity-instance'
};

var module = {
	init: function ($elms) {
		module._initElements($elms);
		module._initRotators();
		module._initEvents();
	},

	_initElements: function ($elms) {
		$rotators = $elms;
	},

	_initRotators: function () {
		var i;
		var $rotator;

		for (i = 0; i < $rotators.length; i++) {
			$rotator = $rotators.eq(i);
			module._initRotator($rotator);
		}
	},

	_initRotator: function ($rotator) {
		var rotatorType = $rotator.attr('data-rotator-type') || 'content';
		var rotatorNav = $rotator.attr('data-rotator-nav') || '';
		var settings = module._getRotatorSettings($rotator, rotatorType, rotatorNav);
		var thisFlick = new Flickity($rotator[0], settings);

		if (rotatorType === 'pod' || rotatorType === 'gallery') {
			module._customArrowInit(thisFlick);
		}

		$rotator.data(dataSelectors.flickity, thisFlick);

		if (!settings.wrapAround) {
			thisFlick.on('change', module._setArrowStates($rotator));
			module._setArrowStates($rotator).call(thisFlick, 0);
		}
	},

	_customArrowInit: function (rotator) {
		var $rotator = rotator.element;
		var $parentElm = $rotator.closest('.js-control');
		var $arrows = $parentElm.querySelectorAll('.js-custom__arrow');
		$($arrows).on('click', module._processArrowClick(rotator));
	},

	_processArrowClick: function (rotator) {
		return function (event) {
			event.preventDefault();
			var $rotator = rotator.element;
			var $parentElm = $rotator.closest('.js-control');
			var $arrows = $($parentElm.querySelectorAll('.js-custom__arrow'));

			var $next = $arrows.filter('.is-next');
			var $prev = $arrows.not('.is-next');

			if ($(this).hasClass('is-next')) {
				rotator.next();
			} else {
				rotator.previous();
			}

			var currentSlide = rotator.selectedIndex;
			var totalSlides = rotator.slides.length;

			if (currentSlide <= 0) {
				$prev.addClass('is-disabled');
			} else {
				$prev.removeClass('is-disabled');
			}

			if ((currentSlide + 1) === totalSlides) {
				$next.addClass('is-disabled');
			} else {
				$next.removeClass('is-disabled');
			}
		};
	},

	_initEvents: function () {
		$(selectors.prev).on('click', module._prev);
		$(selectors.next).on('click', module._next);
	},

	// Return the settings for the type of rotator that has been initialised
	// These can be edited, and new types can be added
	// Documentation for the options can be found here: https://flickity.metafizzy.co/
	_getRotatorSettings: function ($rotator, rotatorType, rotatorNav) {
		var settings;
		var arrowShape = {
			x0: 10,
			x1: 60, y1: 50,
			x2: 65, y2: 45,
			x3: 20
		};

		switch (rotatorType) {

			case 'image':
				settings = {
					prevNextButtons: false,
					autoPlay: false,
					wrapAround: false,
					lazyLoad: true
				};
				break;

			case 'gallery':
				settings = {
					prevNextButtons: false,
					arrowShape: arrowShape,
					//wrapAround: true,
					lazyLoad: 1,
					pageDots: true
				};
				break;

			case 'story':
				settings = {
					prevNextButtons: true,
					arrowShape: 'M2.6,47.6c-1.4,1.3-1.4,3.5,0,4.8L33.2,83c1.3,1.4,3.5,1.4,4.9,0c1.3-1.3,1.3-3.5,0-4.9L13.3,53.4H95c1.9,0,3.4-1.6,3.4-3.5 s-1.5-3.4-3.4-3.4H13.3l24.8-24.8c1.3-1.3,1.3-3.5,0-4.8c-1.4-1.4-3.6-1.4-4.9,0C33.2,17,2.6,47.6,2.6,47.6z',
					pageDots: true,
					wrapAround: false,
					lazyLoad: 1,
					cellAlign: 'left',
				};
				break;

			case 'testimonial':
				settings = {
					prevNextButtons: true,
					arrowShape: 'M2.6,47.6c-1.4,1.3-1.4,3.5,0,4.8L33.2,83c1.3,1.4,3.5,1.4,4.9,0c1.3-1.3,1.3-3.5,0-4.9L13.3,53.4H95c1.9,0,3.4-1.6,3.4-3.5 s-1.5-3.4-3.4-3.4H13.3l24.8-24.8c1.3-1.3,1.3-3.5,0-4.8c-1.4-1.4-3.6-1.4-4.9,0C33.2,17,2.6,47.6,2.6,47.6z',
					pageDots: true,
					wrapAround: true,
					lazyLoad: 4,
					cellAlign: 'left',
					groupCells: true,
				};
				break;

			case 'pod':
				settings = {
					prevNextButtons: false,
					pageDots: true,
					//wrapAround: true,
					lazyLoad: 3,
					cellAlign: 'left',
					groupCells: true
				};
				break;

			default:
				settings = {

				};
		}
		return settings;
	},

	_prev: function (e) {
		var $wrapper = $(e.target).closest(selectors.wrapper);
		var $rotator = $wrapper.find(selectors.rotator);
		var flick = $rotator.data(dataSelectors.flickity);

		flick.previous();
	},

	_next: function (e) {
		var $wrapper = $(e.target).closest(selectors.wrapper);
		var $rotator = $wrapper.find(selectors.rotator);
		var flick = $rotator.data(dataSelectors.flickity);

		flick.next();
	},

	_setArrowStates: function ($rotator) {
		var $wrapper = $rotator.closest(selectors.wrapper);
		var $prev = $wrapper.find(selectors.prev);
		var $next = $wrapper.find(selectors.next);

		return function (index) {
			var flick = this;
			var length = flick.slides.length;

			var isFirst = index === 0;
			var isLast = index >= length - 1;

			$prev.prop('disabled', isFirst);
			$next.prop('disabled', isLast);
		}
	}
};

var Rotator = {
	init: module.init
};

export { Rotator };
