/* app/ui/tooltip/tooltip */
import $ from 'jquery';
import { activate } from 'Util/activate';
import * as Keybinding from 'Util/keybinding';
import { publish, subscribe } from 'Util/pubsub';

var Positions = Object.freeze({
	BOTTOM: 'BOTTOM',
	TOP: 'TOP',
	RIGHT: 'RIGHT',
	TOP_RIGHT: 'TOP_RIGHT',
	LEFT: 'LEFT',
	TOP_LEFT: 'TOP_LEFT'
});

var States = Object.freeze({
	CLOSED: 'CLOSED',
	OPENED: 'OPENED'
});

var selectors = Object.freeze({
	tooltip: '.js-tooltip',
	body: '.js-tooltip__body',

	trigger: '.js-tooltip__trigger',
	close: '.js-tooltip__close',
	toggle: '.js-tooltip__trigger, .js-tooltip__close'
});

var classes = Object.freeze({
	noAnimation: 'c-tooltip--no-animation',

	top: 'c-tooltip--top',
	right: 'c-tooltip--right',
	left: 'c-tooltip--left',

	attentionGrabber: 'c-tooltip--attention-grabber',
});

var pubsubEvents = Object.freeze({
	open: 'tooltip/open',
	close: 'tooltip/close',
	closeAll: 'tooltip/closeAll',
	attentionAll: 'tooltip/attentionAll',

	opened: 'tooltip/opened',
});

var dataSelectors = Object.freeze({
	maxWidth: 'tooltip-max-width'
});

var module = {
	init: function () {
		module._initEvents();
		module._initSubscriptions();
		module._initKeybindings();
	},

	_initEvents: function () {
		$(document)
			.on('click', module._processOutsideClick)
			.on(activate.event, selectors.toggle, activate(module._processClick));
	},

	_initSubscriptions: function () {
		subscribe(pubsubEvents.open, module._open);
		subscribe(pubsubEvents.close, module._close);
		subscribe(pubsubEvents.closeAll, module._closeAll);
		subscribe(pubsubEvents.attentionAll, module._attentionAll);
	},

	_initKeybindings: function () {
		Keybinding.bind('escape', module._closeAll);
	},

	_processOutsideClick: function (e) {
		var $target = $(e.target);

		if (!$target.closest(selectors.tooltip).length) {
			module._closeAll();
		}
	},

	_processClick: function (e) {
		var $target = $(e.target);
		var $tooltip = $target.closest(selectors.tooltip);

		e.preventDefault();

		if (module._getState($tooltip) === States.OPENED) {
			module._close({
				tooltip: $tooltip
			});
		} else {
			module._open({
				tooltip: $tooltip
			});
		}
	},

	_getPosition: function ($tooltip) {
		$tooltip = $tooltip.closest(selectors.tooltip);
		var $body = $tooltip.find(selectors.body);
		var position = Positions.BOTTOM;
		var isShown = module._getState($tooltip) === States.OPENED;

		var gap = 20;
		var bottom;
		var windowBottom;

		var left;
		var windowLeft;

		var right;
		var windowRight;

		if (!isShown) {
			// Open it to check its position, then close it later
			$tooltip.addClass(classes.noAnimation);
			module._setState($tooltip, States.OPENED);
		}
		$tooltip.removeClass(classes.top).removeClass(classes.right).removeClass(classes.left);

		if ($body.data(dataSelectors.maxWidth)) {
			$body.css('max-width', $body.data(dataSelectors.maxWidth));
		}

		bottom = $body.offset().top + $body.outerHeight();
		windowBottom = $(window).scrollTop() + $(window).outerHeight();

		left = $body.offset().left;
		windowLeft = 0;

		right = left + $body.outerWidth();
		windowRight = $(window).outerWidth();

		if ((left - gap) < windowLeft) {
			position = Positions.RIGHT;
		} else if ((right + gap) > windowRight) {
			position = Positions.LEFT;
		}
		if ((bottom + gap) > windowBottom) {
			if (position === Positions.RIGHT) {
				position = Positions.TOP_RIGHT;
			} else if (position === Positions.LEFT) {
				position = Positions.TOP_LEFT;
			} else {
				position = Positions.TOP;
			}
		}

		if (!isShown) {
			// If we opened the tooltip earlier just to check its position, close it now
			module._setState($tooltip, States.CLOSED);
			$tooltip.removeClass(classes.noAnimation);
		}

		return position;
	},

	_setPositionClass: function ($tooltip) {
		var position = module._getPosition($tooltip);

		if (position === Positions.TOP) {
			$tooltip.addClass(classes.top);
			$tooltip.removeClass(classes.right);
			$tooltip.removeClass(classes.left);
		} else if (position === Positions.RIGHT) {
			$tooltip.removeClass(classes.top);
			$tooltip.addClass(classes.right);
			$tooltip.removeClass(classes.left);
		} else if (position === Positions.TOP_RIGHT) {
			$tooltip.addClass(classes.top);
			$tooltip.addClass(classes.right);
			$tooltip.removeClass(classes.left);
		} else if (position === Positions.LEFT) {
			$tooltip.removeClass(classes.top);
			$tooltip.removeClass(classes.right);
			$tooltip.addClass(classes.left);
		} else if (position === Positions.TOP_LEFT) {
			$tooltip.addClass(classes.top);
			$tooltip.removeClass(classes.right);
			$tooltip.addClass(classes.left);
		} else {
			$tooltip.removeClass(classes.top);
			$tooltip.removeClass(classes.right);
			$tooltip.removeClass(classes.left);
		}
	},

	_getState: function ($tooltip) {
		var state;

		if ($tooltip.attr('aria-expanded') === 'true') {
			state = States.OPENED;
		} else {
			state = States.CLOSED;
		}

		return state;
	},

	_getByState: function (state) {
		// Returns a callback for $().filter
		return function(i, el) {
			var $el = $(el);
			var elState = module._getState($el);

			return elState === state;
		};
	},

	_setState: function ($tooltip, state) {
		if (state === States.OPENED) {
			$tooltip.attr('aria-expanded', 'true');
			$(window).trigger('/lazyload/resize');
			publish(pubsubEvents.opened);
		} else {
			$tooltip.attr('aria-expanded', 'false');
			module._clearSize($tooltip);
		}
	},

	_setSize: function ($tooltip) {
		// When transforms are used, partial pixel values
		// cause blurriness. Round height to prevent this

		var $body = $tooltip.find(selectors.body);
		var height = Math.ceil($body.outerHeight());

		if ($tooltip.hasClass(classes.top)) {
			// The blurriness only happens if
			// the tooltip is a "top" position variant

			$body.css('height', height);
		}
	},

	_clearSize: function ($tooltip) {
		var $body = $tooltip.find(selectors.body);

		$body.css('height', '');
	},

	_getTooltips: function ($container) {
		var $tooltips;

		if ($container && $container.length) {
			// All tooltips within this container
			$tooltips = $container.find(selectors.tooltip);
		} else {
			// All tooltips everywhere
			$tooltips = $(selectors.tooltip);
		}

		return $tooltips;
	},

	_open: function (data) {
		var $tooltip = $(data.tooltip).closest(selectors.tooltip);

		module._closeAll();

		module._setState($tooltip, States.OPENED);

		module._setPositionClass($tooltip);

		module._setSize($tooltip);
	},

	_close: function (data) {
		var $tooltip = $(data.tooltip).closest(selectors.tooltip);

		module._setState($tooltip, States.CLOSED);
	},

	_closeAll: function (data) {
		var $container = $(data && data.container);
		var $tooltips = module._getTooltips($container);

		$tooltips = $tooltips.filter(module._getByState(States.OPENED));

		module._setState($tooltips, States.CLOSED);
	},

	_attentionAll: function (data) {
		var $container = $(data && data.container);
		var $tooltips = module._getTooltips($container);

		$tooltips.removeClass(classes.attentionGrabber);
		// Use setTimeout to ensure the class being added
		// back will cause the animation to fire again
		window.setTimeout(function () {
			$tooltips.addClass(classes.attentionGrabber);
		}, 0);
	},
};


var Tooltip = {
	init: module.init
};

export { Tooltip };