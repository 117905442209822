<template>
	<div class="c-form__checkbox" v-show="input.condition()">
		<label class="o-fake-input__wrapper">
			<input :id="input.htmlId"
				   :name="input.htmlName"
				   type="checkbox"
				   value="true"
				   class="o-fake-input c-fake-checkbox"
				   :required="input.validation?.required === true"
				   :checked="input.value === true"
				   :disabled="input.disabled()"
				   v-model="input.value"
				   @change="input.update()"
				   :aria-describedby="input.ariaDescribedby" />

			<span class="o-fake-input__display c-fake-checkbox__display" :class="{'has-error': input.error}"></span>
		</label>
		<div>
			<label class="c-form__label" :for="input.htmlId" v-if="input.hasLabel()" v-html="input.label"/>
			<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'teraform-checkbox',
		props: ['input'],
		data() {
			return {
			}
		},
		mounted() {
			if (!!this.input.value === false) {
				this.input.value = false;
			}
			// console.log('teraform-checkbox', this.input);
		},
		methods: {
		}
	};
</script>