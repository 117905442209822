/* Throttle v1.0 */

var throttle = function (one, two) {
	// Create a version of fn that will execute only if it
	// hasn't been called successfully within the last delay ms

	var timeout;
	var fn;
	var delay;

	if (typeof one === 'function') {
		fn = one;
		delay = two;
	} else {
		fn = two;
		delay = one;
	}

	return function () {
		if (!timeout) {
			fn.apply(this, arguments);

			timeout = window.setTimeout(function () {
				timeout = undefined;
			}, delay);
		}
	};
};

export { throttle };
