<template>
	<div v-if="loaded" v-show="input.condition()">
		<input type="hidden" :name="input.htmlName" :value="input.value" />

		<div class="c-form__range js-calculator-range" :class="{'is-disabled': input.disabled()}">
			<label :for="input.htmlId" class="c-form__range-label c-form__range-label--name" v-if="input.hasLabel()">{{input.label}}</label>

			<label class="c-form__range-label c-form__range-label--min">{{input.displayMin}}</label>
			<div class="c-form__range-input-wrap">
				<input :id="input.htmlId"
					   type="range"
					   class="c-form__range-input js-calculator-range-input"
					   min="0"
					   step="1"
					   :max="input.valuesParsed.length - 1"
					   :aria-valuetext="input.displayValue"
					   :disabled="input.disabled()"
					   v-model="input.proxy"
					   @input="input.updateValues(), input.update()"
					   :aria-describedby="input.ariaDescribedby" />

				<div class="c-form__range-tooltip-track">
					<span class="c-form__range-tooltip" :style="{left: input.offset}">{{input.displayValue}}</span>
					<span class="c-form__range-focus" :style="{left: input.offset}"></span>
				</div>
			</div>
			<label class="c-form__range-label c-form__range-label--max">{{input.displayMax}}</label>
		</div>

		<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>
	</div>
</template>
<script>
	let setup = function ($input) {
		let app = {
			init: function () {
				if (!!$input.valuesParsed === false) {
					$input.valuesParsed = $input.values;
				}

				// 'values' is required
				if (typeof $input.values === 'undefined') {
					throw new Error('Teraform: \'values\' is required for teraform-range');
				}

				// Use 'value' to determine proxy
				if (typeof $input.value !== 'undefined') {
					$input.proxy = app.getProxy($input.value);
				}
				// If 'value' has not been defined, use the first value
				if (typeof $input.proxy === 'undefined') {
					$input.proxy = 0;
				}

				// Call update function to set initial values for displayValue and offset
				app.update();
				$input.updateValues = app.update;

				// Initialise min and max labels
				$input.displayMin = $input.valuesParsed[0].displayValue;
				$input.displayMax = $input.valuesParsed[$input.valuesParsed.length - 1].displayValue;
			},
			getProxy: function (value) {
				var i;

				for (i = 0; i < $input.valuesParsed.length; i++) {
					if ($input.valuesParsed[i].value === value) {
						return i;
					}
				}
			},
			update: function () {
				app.updateValue($input.proxy);
				app.updateOffset($input.proxy);
			},
			updateOffset: function (proxy) {
				var min = 0;
				var max = $input.valuesParsed.length - 1;
				var offset;

				if (typeof proxy === 'undefined') {
					proxy = $input.proxy;
				}

				offset = (proxy - min) / (max - min) * 100 + '%';

				$input.offset = offset;
			},
			updateValue: function (proxy) {
				var valueObj;
				var value;
				var displayValue;

				if (typeof proxy === 'undefined') {
					proxy = $input.proxy;
				}
				// Ensure proxy is an integer
				proxy = Math.round(proxy);

				valueObj = $input.valuesParsed[proxy];
				value = valueObj.value;
				displayValue = valueObj.displayValue;

				$input.value = value;
				$input.displayValue = displayValue;
			}
		};

		app.init($input);
	};

	export default {
		name: 'teraform-range',
		props: ['input'],
		data() {
			return {
				loaded: false
			}
		},
		mounted() {
			setup(this.input);
			//console.log({
			//	name: 'teraform-range',
			//	input: this.input
			//});
			this.loaded = true;
		},
		methods: {
		}
	};
</script>