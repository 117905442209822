import $ from 'jquery';
import { activate } from 'Util/activate';

var selectors = {
	expand: '.js-chat-expand'
};

var module = {
	init: function () {
		module._initEvents();
	},

	_initEvents: function () {
		$(selectors.expand).on(activate.event, activate(module._expandEvent));
	},

	_expandEvent: function (e) {
		e.preventDefault();

		module._expand();
	},

	_expand: function () {
		if (module._apiReady()) {
			window.olark('api.box.expand');
		}
	},

	_apiReady: function () {
		var apiReady = typeof window.olark === 'function';

		return apiReady;
	}
};

var chat = {
	init: module.init
};

export { chat };