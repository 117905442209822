/* app/ui/map/map.interactive */
import $ from 'jquery';
import { permission } from 'App/permission/permission';
import { publish, subscribe } from 'Util/pubsub';

var OverlappingMarkerSpiderfier;
var MarkerClusterer;

var locationCache;//store all the location data
var $map;
var googleMap;
var allMarkers = [];
var markerSpiderfier;
var markerClusters = [];
var currentLat;
var currentLng;
var centerPos;
var zoom;
var $addressInput;
var googAutocomplete;
var mapLoadType = {
	geoDisableMode: 1,
	geoEnableMode: 2,
	searchMode: 3
};
var storesByDistance;
var storeNameArray;

var storesToShow = 8;

// Spiderfier Config
var spiderConfig = {
	keepSpiderfied: true,
	event: 'click',
	circleSpiralSwitchover: 'Infinity',
	markersWontMove: true,
	markersWontHide: false,
};

// Cluster Config
var clusterConfig = {
	maxZoom: 16
};

var MapInteractive = {
	init: function($elm) {
		MapInteractive = this;
		$map = $elm;
		if ($map.length === 0) {
			return;
		}
		$addressInput = $('.js-location-autocomplete');
		MapInteractive._initSubscriptions();
	},

	_initSubscriptions: function() {
		subscribe('/map/apiLoaded', MapInteractive._initMap);
	},

	_initMap: function () {
		const clustererPromise = import('Node/@googlemaps/markerclusterer');
		const spiderfierPromise = import('Node/overlapping-marker-spiderfier');
		Promise.all([clustererPromise, spiderfierPromise]).then(([clustererModule, spiderfierModule]) => {
			MarkerClusterer = clustererModule.MarkerClusterer;

			// OverlappingMarkerSpiderfier needs the Google API to exist or it errors, so
			// we wait for the API to be ready before importing it
			OverlappingMarkerSpiderfier = spiderfierModule.default;

			var isInitialised = false;

			permission.get({
				type: 'geolocation',
				yes: function() {
					navigator.geolocation.getCurrentPosition(
						MapInteractive._getCurrentPosition,
						MapInteractive._geoLocationError
					);
					if (!isInitialised) {
						MapInteractive._initAutoComplete();
					}
				},
				no: function() {
					if (!isInitialised) {
						MapInteractive._populateMap(mapLoadType.geoDisableMode);
						MapInteractive._initAutoComplete();
					}
				},
				prompt: function() {
					// Make sure prompt has even px height, so it won't look blurred
					var $prompt = $('.js-permission-location-check');
					var height = $prompt.outerHeight();
					var paddingTop;
					if (height % 2) {
						paddingTop = parseInt($prompt.css('padding-top'), 10);
						$prompt.css('padding-top', paddingTop + 1 + 'px');
					}

					// Pre-load the map without location information
					MapInteractive._populateMap(mapLoadType.geoDisableMode);
					MapInteractive._initAutoComplete();
					isInitialised = true;
				}
			});
		});
	},

	_geoLocationError: function() {
		MapInteractive._populateMap(mapLoadType.geoDisableMode);
	},

	_populateMap: function(loadType) {
		//Start of Google map call
		MapInteractive._getMapData(function(data) {
			allMarkers = [];
			var obj = data;
			var defaultZoom = 6;
			if (loadType === mapLoadType.geoDisableMode) {
				centerPos = new google.maps.LatLng(-40.272709, 173.570000);
			}

			google.maps.visualRefresh = false;
			var markerBounds = new google.maps.LatLngBounds();

			// Google Map Config
			var mapConfig = {
				mapTypeId: google.maps.MapTypeId.ROAD,
				center: centerPos,
				zoom: defaultZoom,
				streetViewControl: false,
				overviewMapControl: false,
				mapTypeControl: false,
				maxZoom: 17,
				panControl: false,
				zoomControl: true,
				zoomControlOptions: {
					style: google.maps.ZoomControlStyle.LARGE,
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				backgroundColor: '#a3ccff'
			};

			// Set Google Map
			googleMap = new google.maps.Map($map.get(0), mapConfig);

			// when extends to an marker, set the max zoom
			google.maps.event.addListener(googleMap, 'zoom_changed', function() {
				var zoomChangeBoundsListener =
					google.maps.event.addListener(googleMap, 'bounds_changed', function(event) {
						if (this.getZoom() > 15 && this.initialZoom === true) {
							// Change max/min zoom here
							this.setZoom(15);
							zoom = this.getZoom();
							centerPos = this.getCenter();
							this.initialZoom = false;
						}
						google.maps.event.removeListener(zoomChangeBoundsListener);
					});
			});

			// Set Spiderfier
			markerSpiderfier = new OverlappingMarkerSpiderfier(googleMap, spiderConfig);

			// Set Marker Variants Arrays
			var newMarker;

			//Create Infobox
			var myOptions = {
				alignBottom: true,
				boxClass: 'infowindow js-infobox',
				closeBoxURL: '/content/images/interface/map/close.png',
				closeBoxMargin: '0',
				enableEventPropagation: true,
				infoBoxClearance: new google.maps.Size(0, 32),
				maxWidth: 400,
				//pixelOffset: new google.maps.Size(-120, -70)
				//onClick: infoClick
			};

			var ib = new google.maps.InfoWindow(myOptions);

			//Loop through the list
			for (var i = 0; i < obj.length; i++) {

				if (obj[i].coordinates && obj[i].coordinates.latitude && obj[i].coordinates.longitude) {
					var mapmarker = '/content/images/interface/map/marker.png';
					var lat = parseFloat(obj[i].coordinates.latitude);
					var lng = parseFloat(obj[i].coordinates.longitude);

					var myLatLng = new google.maps.LatLng(lat, lng);

					var markerOptions = {
						position: myLatLng,
						map: googleMap,
						region: obj[i].region,
						name: obj[i].name,
						email: obj[i].email,
						phone: obj[i].phone,
						id: obj[i].id,
						icon: mapmarker,
						address: obj[i].address,
						location: obj[i].location,
						url: obj[i].url,
						lat: parseFloat(obj[i].coordinates.latitude),
						lng: parseFloat(obj[i].coordinates.longitude)
					};

					// Populate markers array for list links

					newMarker = new google.maps.Marker(markerOptions);
					markerSpiderfier.addMarker(newMarker);
					allMarkers.push(newMarker);
				}
			} // end of for loop

			//Add infobox click handler
			markerSpiderfier.addListener('click', function(marker) {
				var region = '<div class="c-map-infobox-branch-region">' + marker.region + '</div>';
				var title = '<a href="' + marker.url + '" class="c-map-infobox-branch-title o-link-brand">' + marker.name + '</a>';
				var address = marker.address ? '<div class="c-map-infobox-branch-address"><span class="iconf-location c-map-infobox-branch-address__icon"></span>' + marker.address + '</div>' : '';
				var phone = '<div class="c-map-infobox-branch-contact"><span class="iconf-phone c-map-infobox-branch-contact__icon"></span><a class="o-link-brand" href="tel:' + marker.phone + '">' + marker.phone + '</a></div>';
				var email = '<div class="c-map-infobox-branch-contact"><span class="iconf-email c-map-infobox-branch-contact__icon"></span><a class="o-link-brand" href="mailto:' + marker.email + '">' + marker.email + '</a></div>';
				var getDirections = '<div class="c-map-infobox-branch-directions"><a class="o-link-brand" target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=' + marker.location + '">Get directions</a><span class="c-branch-detail-info-direction__icon iconf-external-link"></span></div>';
				var iconRight = '<a href="' + marker.url + '"><span class="iconf-arrow-right c-interactive-map-infowindow__icon t-text"></span></a>';
				var content = '<div class="c-interactive-map-infowindow">' + region + title + address + getDirections + phone + email + iconRight + '</div>';
				ib.setContent(content);
				ib.open(googleMap, marker);
				googleMap.panTo(ib.getPosition());
			});

			//comment the cluster function for now
			MapInteractive._cluster(googleMap, allMarkers);

			var nearestMarker;
			var nearestDistance;

			// get geolocation and auto zoom, just show the nearest one
			if (loadType === mapLoadType.geoEnableMode) {
				googleMap.setCenter(centerPos);
				nearestMarker = null;
				nearestDistance = 99999999;
				allMarkers.forEach(function(item) {
					var itemDistance = MapInteractive._calcDistance(item.getPosition(), centerPos);
					if (!nearestMarker) {
						nearestMarker = item;
						nearestDistance = MapInteractive._calcDistance(nearestMarker.getPosition(), centerPos);
					} else {
						if (itemDistance < nearestDistance) {
							nearestMarker = item;
							nearestDistance = itemDistance;
							//console.log(itemDistance);
						}
					}
				});
				if (nearestMarker) {
					markerBounds.extend(nearestMarker.getPosition());
					googleMap.initialZoom = true;
					googleMap.fitBounds(markerBounds);
				}
			}

			//address autocomplete search, store show rules:
			// Show closest 6 stores
			if (loadType === mapLoadType.searchMode) {
				googleMap.setCenter(centerPos);

				storesByDistance = [].concat(allMarkers);

				// Cache distances so they aren't recalculated during sort
				storesByDistance = storesByDistance.map(function(store) {
					return {
						store: store,
						distance: MapInteractive._calcDistance(store.getPosition(), centerPos)
					};
				});

				// Sort by distance
				storesByDistance = storesByDistance.sort(function(a, b) {
					return a.distance - b.distance;
				});

				var toShowStores = storesByDistance.slice(0, storesToShow);

				// Retrieve original store objects
				toShowStores = toShowStores.map(function(storeObj) {
					return storeObj.store;
				});

				if (toShowStores.length) {
					for (var j = 0; j < toShowStores.length; j++) {
						markerBounds.extend(toShowStores[j].position);
						storeNameArray = toShowStores.map(function(item) {
							return item.name;
						});
					}
					googleMap.fitBounds(markerBounds);
				} else if (nearestMarker) {
					markerBounds.extend(nearestMarker.getPosition());
					googleMap.initialZoom = true;
					googleMap.fitBounds(markerBounds);
					storeNameArray.push(nearestMarker.name);
				}

				publish('/map/search', { storeName: storeNameArray });
			}

		}); //End of Google map call
	},

	_getMapData: function(callback) {
		if (locationCache) {
			callback(locationCache);
		} else {
			var url = $map.attr('data-dataurl');
			$.get(url, function(data) {
				locationCache = data;
				callback(data);
			});
		}
	},

	_cluster: function (googleMap, markers) {

		const renderer = {
			render({ count, position }, stats) {
				// change color if this cluster has more markers than the mean cluster
				const color = '#38A0A1';
				// const color = count > Math.max(10, stats.clusters.markers.mean) ? "#ff0000" : "#0000ff";
				// create svg url with fill color
				const svg = window.btoa(`
							  <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
								<circle cx="120" cy="120" r="90" />
							  </svg>`);
				// create marker using svg icon
				return new google.maps.Marker({
					position,
					icon: {
						url: `data:image/svg+xml;base64,${svg}`,
						scaledSize: new google.maps.Size(45, 45),
					},
					label: {
						text: String(count),
						color: 'rgba(255,255,255,1)',
						fontSize: '14px',
						fontWeight: 'bold'
					},
					title: `Cluster of ${count} markers`,
					// adjust zIndex to be above other markers
					zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
				});
			}
		};

		var markerCluster = new MarkerClusterer({
			map: googleMap,
			markers,
			renderer,
		});
	},

	_getCurrentPosition: function(position) {
		currentLat = position.coords.latitude;
		currentLng = position.coords.longitude;
		centerPos = new google.maps.LatLng(currentLat, currentLng);
		zoom = 11;
		MapInteractive._populateMap(mapLoadType.geoEnableMode);
	},

	_calcDistance: function(p1, p2) {
		return google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000;
	},

	//address auto complete
	_initAutoComplete: function() {
		if ($addressInput.length) {
			$addressInput.focus(function() {
				$(this).val('');
			});
			googAutocomplete = new google.maps.places.Autocomplete($addressInput[0], { componentRestrictions: { country: 'nz' } });

			setTimeout(function() {
				$addressInput.prop('autocomplete', 'false');
			}, 1000);

			googAutocomplete.addListener('place_changed', function() {
				var place = googAutocomplete.getPlace();
				if (!place.geometry) {
					// User entered the name of a Place that was not suggested
					return;
				}
				if (place.geometry.location) {
					centerPos = place.geometry.location;
					zoom = 12;
					MapInteractive._populateMap(mapLoadType.searchMode);
					$addressInput.blur();
				}
			});
		}
	}
};


export { MapInteractive };
