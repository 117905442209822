<template>
	<div v-show="input.condition()">
		<label class="c-form__label" :for="input.htmlId" v-if="input.hasLabel()">{{input.label}}<span class="c-form__required-optional" v-if="!(input.validation.required || input.hasCustomRequiredValidation)">&nbsp;(optional)</span></label>
		<label class="c-form__label" :for="input.htmlId" v-if="!input.hasLabel() && !(input.validation.required || input.hasCustomRequiredValidation)"><span class="c-form__required-optional">(optional)</span></label>
		<input :id="input.htmlId"
			   :name="input.htmlName"
			   type="date"
			   placeholder="yyyy-mm-dd"
			   class="c-form__input"
			   :min="input.validation?.min"
			   :max="input.validation?.max"
			   :required="input.validation?.required === true"
			   :class="{'has-error': input.error}"
			   :disabled="input.disabled()"
			   v-model="input.value"
			   @change="input.updateValues(), input.update()"
			   :aria-describedby="input.ariaDescribedby" />
		<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
		<span class="c-form__error" v-if="input.errors.errorPattern" v-show="input.error === 'pattern'">{{input.errors.errorPattern}}</span>
		<span class="c-form__error" v-if="input.errors.errorMin" v-show="input.error === 'min'">{{input.errors.errorMin}}</span>
		<span class="c-form__error" v-if="input.errors.errorMax" v-show="input.error === 'max'">{{input.errors.errorMax}}</span>
		<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>
	</div>
</template>
<script>
	let util = {
		dateToString(dateObj) {
			let year;
			let month;
			let day;

			if ((dateObj instanceof Date) === false) {
				throw Error('{dateObj} must be instance of Date');
			}

			year = dateObj.getFullYear();
			month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
			day = ('0' + dateObj.getDate()).slice(-2);

			return `${year}-${month}-${day}`;
		}
	};

	let setup = function ($input) {
		//let ctrl = {
		//	input: $input,
		//};

		//var init = function () {
		//	var dateValue;

		//	// 'placeholder' will be 'yyyy-mm-dd' if undefined.
		//	// This is only used for browsers that don't support
		//	// native input[type="date"], such as IE11
		//	if (typeof ctrl.placeholder === 'undefined') {
		//		ctrl.placeholder = 'yyyy-mm-dd';
		//	}

		//	if (typeof ctrl.value !== 'undefined') {
		//		// Ensure value is a Date object, as required by AngularJS

		//		if ((ctrl.value instanceof Date) === false) {
		//			if (ctrl.value === '') {
		//				// Allow an empty string to represent the current date,
		//				// so it can be used for initialisation
		//				dateValue = new Date();
		//			} else {
		//				dateValue = new Date(ctrl.value);
		//			}
		//			ctrl.value = dateValue;
		//		}

		//		ctrl.input.value = ctrl.value;
		//	}

		//	// JS config can override 'min', if present in validation
		//	if (typeof ctrl.min === 'undefined') {
		//		ctrl.min = ctrl.input.validation.min;
		//	}
		//	// JS config can override 'max', if present in validation
		//	if (typeof ctrl.max === 'undefined') {
		//		ctrl.max = ctrl.input.validation.max;
		//	}

		//	if (ctrl.input.validation.required !== true) {
		//		// Because of how AngularJS handles date inputs,
		//		// they will only appear to have a value if that
		//		// value is valid. So if the field is not required,
		//		// then any validation rules that were specified
		//		// should be ignored.
		//		ctrl.input.validation = {};

		//		if (typeof ctrl.min !== 'undefined') {
		//			console.warn('Teraform: teraform-date \'min\' value will be ignored because the field is not required');
		//			delete ctrl.min;
		//			delete ctrl.input.min;
		//		}
		//		if (typeof ctrl.max !== 'undefined') {
		//			console.warn('Teraform: teraform-date \'max\' value will be ignored because the field is not required');
		//			delete ctrl.max;
		//			delete ctrl.input.max;
		//		}
		//	} else {
		//		// If 'min' was specified, move it into the validation
		//		if (typeof ctrl.min !== 'undefined') {
		//			if (typeof ctrl.input.validation.min === 'undefined') {
		//				ctrl.input.validation.min = ctrl.min;
		//			}
		//			delete ctrl.min;
		//			delete ctrl.input.min;
		//		}

		//		// If 'max' was specified, move it into the validation
		//		if (typeof ctrl.max !== 'undefined') {
		//			if (typeof ctrl.input.validation.max === 'undefined') {
		//				ctrl.input.validation.max = ctrl.max;
		//			}
		//			delete ctrl.max;
		//			delete ctrl.input.max;
		//		}
		//	}
		//};

		//module.components.createController(ctrl, init, undefined, timeoutService, rootScopeService, transcludeService);

		//var stringToDateUnambiguous = function (datestring) {
		//	// Ensures a datestring is converted correctly into a date
		//	// at midnight, with no time zone weirdness.

		//	// Accepts only formats like these:
		//	// yyyy-mm-dd
		//	// yyyy/mm/dd
		//	// yyyy mm dd

		//	var pattern = /^(\d{4})\D(\d{2})\D(\d{2})$/;
		//	var parts = datestring.match(pattern);

		//	var year;
		//	var month;
		//	var day;
		//	var date;

		//	if (!parts) {
		//		throw new Error('Teraform: \'min\' and \'max\' validation strings must be formatted like \'yyyy-mm-dd\'');
		//	}

		//	year = parseInt(parts[1], 10);
		//	month = parseInt(parts[2], 10) - 1;
		//	day = parseInt(parts[3], 10);

		//	date = new Date(year, month, day);

		//	return date;
		//};

		//ctrl.componentValidation = function (value) {
		//	var input = this;
		//	var rules = input.validation;

		//	var minDate;
		//	var maxData;

		//	if (rules.required) {
		//		if (value instanceof Date && isNaN(value.getDate())) {
		//			return ErrorTypes.REQUIRED;
		//		}
		//	}

		//	if (rules.min) {
		//		minDate = stringToDateUnambiguous(rules.min);
		//		if (value < minDate) {
		//			return ErrorTypes.MIN;
		//		}
		//	}

		//	if (rules.max) {
		//		maxDate = stringToDateUnambiguous(rules.max);
		//		if (value > maxDate) {
		//			return ErrorTypes.MAX;
		//		}
		//	}

		//	return true;
		//};

		//=============

		// 'placeholder' will be 'yyyy-mm-dd' if undefined.
		// This is only used for browsers that don't support
		// native input[type="date"], such as IE11
		if (typeof $input.placeholder === 'undefined') {
			$input.placeholder = 'yyyy-mm-dd';
		}

		if (typeof $input.value !== 'undefined') {
			// Ensure value is a Date object, as required by AngularJS
			let dateValue;

			if (($input.value instanceof Date) === false) {
				if ($input.value === '') {
					// Allow an empty string to represent the current date,
					// so it can be used for initialisation
					dateValue = new Date();
				} else {
					dateValue = new Date($input.value);
				}
				$input.valueObj = dateValue;
			} else {
				var formattedDate = util.dateToString($input.value);
				$input.valueObj = new Date(formattedDate);
				$input.value = formattedDate;
			}
		}

		if (typeof $input.validation !== 'undefined') {

			let min = $input.validation.min;
			let max = $input.validation.max;

			if (min instanceof Date) {
				$input.validation.min = util.dateToString(min);
			}

			if (max instanceof Date) {
				$input.validation.max = util.dateToString(max);
			}
		}

		$input.updateValues = function () {
			$input.valueObj = new Date($input.value);
			//console.log({
			//	value: $input.value,
			//	valueObj: $input.valueObj,
			//	isDate: $input.value instanceof Date,
			//	type: typeof $input.value
			//});
		};
	};

	export default {
		name: 'teraform-date',
		props: ['input'],
		data() {
			return {
			}
		},
		mounted() {
			setup(this.input);
			// console.log('teraform-date', this.input);
		},
		methods: {
		}
	};
</script>