const ErrorTypes = Object.freeze({
	REQUIRED: 'required',
	PATTERN: 'pattern',
	MIN: 'min',
	MAX: 'max',
	RANGE: 'range',
	FILE_FORMAT: 'file-format',
	FILE_SIZE: 'file-size',
	CUSTOM: 'custom'
});

const Patterns = Object.freeze({
	EMAIL: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
	NAME: /^[^0-9~!@#$%^&*()_=+\\\/""\<\>\?]*$/,
	PHONE: /^[\s+()-]*(\d[\s+()-]*){1,15}$/,
	POSTCODE: /^\d{4}$/,

	ALPHA_SPACES: /^[A-Za-zāēīōūĀĒĪŌŪ\s]+$/,
	ALPHA: /^[A-Za-zāēīōūĀĒĪŌŪ]+$/,
	ALPHA_NUM_SPACES: /^[0-9A-Za-zāēīōūĀĒĪŌŪ\s]+$/,
	ALPHA_NUM: /^[0-9A-Za-zāēīōūĀĒĪŌŪ]+$/,
	NUM: /^[0-9]+$/,

	// Loan Application
	VERSION: /^(V|v)\d+\.\d+\w?$/,
	FIN_DEPS: /^\d\+?$/,
	STREET_NUM: /^[0-9A-Za-z\-]+$/,
	STREET_NAME: /^[0-9A-Za-zāēīōūĀĒĪŌŪ\-'\s]+$/,
	YEAR: /^\d{4}$/,
	MONTH: /^\d{2}$/,
	EMP_STATUS: /^[0-9A-Za-z\s\+]+$/,
	FREE_TEXT: /^[^~@#%^*()_=+\\\/""\<\>]*$/,

	// Refer a friend
	BRANCH_EMAIL: /^[^@]+@(myfinance\.co\.nz|if\.co\.nz)$/,

	// Loan Payment
	LOAN_ID: /^[0-9]{7,}(s|S)?$/,
	DECIMAL: /^\d+(\.\d{1,2})?$/,
});

const Rules = {};

// Return a fresh object each time so no two inputs
// share the same validation, in case it gets modified
Object.defineProperties(Rules, {
	'REQUIRED': {
		get: function () {
			return {
				required: true
			};
		},
		enumerable: true
	},

	'EMAIL': {
		get: function () {
			return {
				pattern: Patterns.EMAIL
			};
		},
		enumerable: true
	},

	'EMAIL_REQUIRED': {
		get: function () {
			return {
				required: true,
				pattern: Patterns.EMAIL
			};
		},
		enumerable: true
	},

	'NAME': {
		get: function () {
			return {
				pattern: Patterns.NAME,
				maxlength: 42
			};
		},
		enumerable: true
	},

	'NAME_REQUIRED': {
		get: function () {
			{
				return {
					required: true,
					pattern: Patterns.NAME,
					maxlength: 42
				};
			}
		},
		enumerable: true
	}
});
Object.freeze(Rules);

export { ErrorTypes, Patterns, Rules };