<template>
	<div class="c-form__radio" v-show="input.condition()">
		<label class="c-form__label c-form__radio-label" v-if="input.hasLabel()">{{input.label}}<span class="c-form__required-optional" v-if="!(input.validation.required || input.hasCustomRequiredValidation)">&nbsp;(optional)</span></label>
		<label class="c-form__label c-form__radio-label" v-if="!input.hasLabel() && !(input.input.validation.required || input.hasCustomRequiredValidation)"><span class="c-form__required-optional">(optional)</span></label>
		<div v-if="loaded">
			<ul class="c-form__radio-list" :id="input.htmlId">
				<li class="c-form__radio-item" v-for="option in input.valuesParsed">
					<label class="o-fake-input__wrapper">
						<input :id="option.id"
							   :name="input.htmlName"
							   type="checkbox"
							   class="o-fake-input c-fake-radio"
							   :required="input.validation?.required === true"
							   :value="option.value"
							   :disabled="input.disabled()"
							   v-model="input.proxy[option.value]"
							   @change="input.updateValues(), input.update()"
							   :aria-describedby="input.ariaDescribedby" />
						<span class="c-fake-radio__display" :class="{'has-error': input.error}">{{option.displayValue}}</span>
					</label>
				</li>
			</ul>
			<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
			<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>
		</div>
	</div>
</template>
<script>
	let setup = function ($input) {
		if (!!$input.values === false) {
			$input.values = [];
		}

		$input.proxy = {};

		if ($input.value && Array.isArray($input.value)) {
			for (let i = 0; i < $input.value.length; i++) {
				let key = $input.value[i];

				$input.proxy[key] = true;
			}
		} else {
			$input.value = [];
		}

		$input.updateValues = function () {

			$input.value = [];

			for (let key in $input.proxy) {
				if ($input.proxy[key]) {
					$input.value.push(key);
				}
			}

			//console.log({
			//	id: $input.id,
			//	proxy: $input.proxy,
			//	value: $input.value
			//});
		};
	};

	export default {
		name: 'teraform-checkboxlist',
		props: ['input'],
		data() {
			return {
				loaded: false
			}
		},
		mounted() {
			setup(this.input);
			this.loaded = true;
			// console.log('teraform-checkboxlist', this.input);
		},
		methods: {
		}
	};
</script>