<template>
	<div class="c-app-form__step">
		<teraform-help-panel :panel="content.Step3Help" />

		<form id="app-form-step-3"
			  class="c-form c-app-form__form js-app-form__step"
			  method="post"
			  action="."
			  @submit.prevent="actions.validateWithErrorLogging($event, actions.submitStep, stepInputs[state.step])"
			  novalidate>
			<!-- Browsers bind the "enter" key in a form containing buttons to click the first button. Since the first button in this form is not a submit button, put this inaccessible button here to make sure "enter" tries to submit the form as usual. -->
			<button class="u-visuallyhidden" tabindex="-1" aria-hidden="true">Submit</button>

			<h2 class="c-app-form__step-title">{{content.Step3Title}}</h2>
			<p class="c-app-form__step-intro" v-show="conditions.isLoggedOut()">All fields are required unless specified otherwise</p>

			<div class="c-app-form__alert" v-if="content.Step3Introduction">
				<div class="c-notification">
					<div class="c-notification__bar">
						<span class="c-notification__icon iconf-information" aria-hidden="true"></span>
					</div>
					<div class="c-notification__body">
						<div class="c-notification__content" v-html="content.Step3Introduction" />
					</div>
				</div>
			</div>


			<div class="c-app-form__alert" v-if="content.Step3LoggedInIntroduction" v-show="conditions.isLoggedIn() && conditions.isDataCompleteStep3() === true">
				<div class="c-notification">
					<div class="c-notification__bar">
						<span class="c-notification__icon iconf-information" aria-hidden="true"></span>
					</div>
					<div class="c-notification__body">
						<div class="c-notification__content" v-html="content.Step3LoggedInIntroduction" />
					</div>
				</div>
			</div>

			<div class="c-app-form__alert" v-if="content.Step3LoggedInIntroductionMissingFields" v-show="conditions.isLoggedIn() && conditions.isDataCompleteStep3() === false">
				<div class="c-notification o-portal-status--warning">
					<div class="c-notification__body">
						<div class="c-notification__content" v-html="content.Step3LoggedInIntroductionMissingFields" />
					</div>
				</div>
			</div>

			<!--RESIDENCY-->
			<section class="c-form__section" v-show="!conditions.isStepLocked(3)">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="Residency-0">{{content.FieldLabelResidency}}</label>
					</h3>
					<teraform-tooltip :text="content.FieldTooltipResidency" />
				</div>

				<div class="c-form__field">
					<teraform-radio :input="inputs.Residency" />
				</div>

				<div class="c-form__field-split" v-if="inputs.VisaType.condition()">
					<div class="c-form__field">
						<teraform-select :input="inputs.VisaType" />
					</div>
					<div class="c-form__field">
						<teraform-date :input="inputs.VisaExpiry" />
					</div>
				</div>
			</section>
			<!--/ RESIDENCY-->
			<!--PHOTO ID-->
			<section class="c-form__section" v-show="!conditions.isStepLocked(3)">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="ProofType-0">{{content.FieldLabelIdentityProofType}}</label>
					</h3>
					<teraform-tooltip :text="content.FieldTooltipIdentityProofType" />
				</div>

				<div class="c-form__field">
					<teraform-radio :input="inputs.ProofType" />
				</div>

				<div v-if="inputs.DriverLicenceNumber.condition()" class="c-form__field-split--m">
					<div class="c-form__field">
						<teraform-text :input="inputs.DriverLicenceNumber" />
					</div>

					<div class="c-form__field">
						<teraform-text :input="inputs.DriverLicenceVersion" />
					</div>
				</div>

				<div v-if="inputs.DriverLicenceIdUpload.condition()" class="c-form__field">
					<teraform-fileUploaded :input="inputs.DriverLicenceIdUpload" />
				</div>

				<div v-if="inputs.PassportNumber.condition()" class="c-form__field-split--m">
					<div class="c-form__field">
						<teraform-text :input="inputs.PassportNumber" />
					</div>

					<div class="c-form__field">
						<teraform-date :input="inputs.PassportExpiry" />
					</div>

					<div class="c-form__field" v-show="inputs.PassportCountry.condition()">
						<teraform-select :input="inputs.PassportCountry" />
					</div>
				</div>

				<div v-if="inputs.PassportIdUpload.condition()" class="c-form__field">
					<teraform-fileUploaded :input="inputs.PassportIdUpload" />
				</div>

				<div v-if="inputs.ProofType.value === 'Other'" class="c-form__field">
					<div class="o-body-text" v-html="content.FieldTextIdentityTypeOther" />
				</div>
			</section>
			<!--/PHOTO ID-->
			<!--SUMMARY VIEW-->
			<section class="c-form__section" v-if="conditions.isStepLocked(3)">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">{{content.SectionLabelIdentityInfo}}</h3>
					<teraform-tooltip :text="content.SectionTooltipIdentityInfo" />
				</div>

				<div class="c-form__field c-form__field--close c-form__field--row">
					<span class="c-form__label" id="ResidencyLabel">{{content.FieldLabelLoggedInResidency}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="ResidencyLabel">
						<span v-if="conditions.hasValue(inputs.Residency.value)">{{inputs.Residency.value}}</span>
						<span v-if="!conditions.hasValue(inputs.Residency.value)" class="c-form__missing">Unknown</span>
					</span>
				</div>

				<div class="c-form__field c-form__field--close c-form__field--row" v-if="conditions.showFieldOrSummary.VisaType()">
					<span class="c-form__label" id="VisaLabel">{{content.FieldLabelVisa}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="VisaLabel">
						<span v-if="conditions.hasValue(inputs.VisaType.value)">{{inputs.VisaType.value}}</span>
						<span v-if="!conditions.hasValue(inputs.VisaType.value)" class="c-form__missing">Type unknown</span>
						<br />
						<span v-if="conditions.hasValue(inputs.VisaExpiry.value)">Expires {{inputs.VisaExpiry.value}}</span>
						<span v-if="!conditions.hasValue(inputs.VisaExpiry.value)" class="c-form__missing">Expiry unknown</span>
					</span>
				</div>

				<div class="c-form__field c-form__field--close c-form__field--row">
					<span class="c-form__label" id="IdentityProofTypeLabel">{{content.FieldLabelIdentityProofType}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="IdentityProofTypeLabel">
						<span v-if="conditions.showFieldOrSummary.DriverLicenceNumber()">
							NZ Driver Licence<br />

							<span v-if="conditions.hasValue(inputs.DriverLicenceNumber.value)">{{inputs.DriverLicenceNumber.value}}</span>
							<span v-if="!conditions.hasValue(inputs.DriverLicenceNumber.value)" class="c-form__missing">Number unknown</span>
							<br />

							<span v-if="conditions.hasValue(inputs.DriverLicenceVersion.value)">Version {{inputs.DriverLicenceVersion.value}}</span>
							<span v-if="!conditions.hasValue(inputs.DriverLicenceVersion.value)" class="c-form__missing">Version unknown</span>
						</span>
						<span v-if="conditions.showFieldOrSummary.PassportNumber()">
							Passport<br />

							<span v-if="conditions.hasValue(inputs.PassportNumber.value)">{{inputs.PassportNumber.value}}</span>
							<span v-if="!conditions.hasValue(inputs.PassportNumber.value)" class="c-form__missing">Number unknown</span>
							<br />
							<span v-if="conditions.hasValue(inputs.PassportExpiry.value)">Expires {{inputs.PassportExpiry.value}}</span>
							<span v-if="!conditions.hasValue(inputs.PassportExpiry.value)" class="c-form__missing">Expiry unknown</span>
							<br />

							<span v-if="conditions.hasValue(inputs.PassportCountry.value)">{{inputs.PassportCountry.value}}</span>
							<span v-if="!conditions.hasValue(inputs.PassportCountry.value)" class="c-form__missing">Country unknown</span>
						</span>
						<span v-if="!(conditions.showFieldOrSummary.DriverLicenceNumber() || conditions.showFieldOrSummary.PassportNumber())">
							<span v-if="conditions.hasValue(inputs.ProofType.value)">{{inputs.ProofType.value}}</span>
							<span v-if="!conditions.hasValue(inputs.ProofType.value)" class="c-form__missing">Unknown</span>
						</span>
					</span>
				</div>
			</section>
			<!--/SUMMARY VIEW-->

			<div class="c-form__controls">
				<div class="c-form__controls-fallback" v-if="conditions.recaptchaFallbackRequired()">
					<div class="c-form__controls-fallback-error c-form__error" v-html="content.ReCaptchaMessage" />
					<div v-if="actions.initRecaptchaFallback()" id="recaptcha" class="g-recaptcha" data-theme="clean" data-size="normal" :data-sitekey="config.recaptcha.fallback"></div>
					<input type="hidden" name="g-Recaptcha-Fallback" value="true" />
				</div>

				<button class="o-btn o-btn--bordered o-btn--outline o-btn--large c-app-form__back" @click="actions.setStep(2)" :disabled="conditions.isBusy()">Back</button>
				<p class="c-form__controls-text c-app-form__submit-text">{{content.ApplicationSaveStatement}}</p>
				<div class="c-form__controls-group">
					<button class="o-btn o-btn--bordered o-btn--outline o-btn--large c-app-form__change" v-if="conditions.showChangeButtonStep3()" @click="actions.unlockStep($event, 3)">
						<span class="o-btn__icon iconf-edit"></span>
						<span class="o-btn__text">Change</span>
					</button>
					<button class="o-btn o-btn--bordered o-btn--outline o-btn--large c-app-form__change" v-if="conditions.showAddButtonStep3()" @click="actions.unlockStep($event, 3)">
						<span class="o-btn__icon iconf-plus"></span>
						<span class="o-btn__text">Add</span>
					</button>

					<button class="o-btn o-btn--supporting o-btn--large o-btn--nowrap c-app-form__submit" type="submit" :disabled="conditions.isBusy() || conditions.disableConfirmButtonStep3()" :class="{'c-loader--small': conditions.isBusy()}">
						<span v-if="conditions.isStepLocked(3)">Confirm and continue</span>
						<span v-if="!conditions.isStepLocked(3)">Next</span>
					</button>
				</div>
			</div>

			<a class="c-form__exit"
			   :target="content.ReturnToPortalLink.Target"
			   :href="content.ReturnToPortalLink.Url"
			   v-if="conditions.isLoggedIn() && content.ReturnToPortalLink">
				{{content?.ReturnToPortalLink?.Name}}
			</a>
		</form>
	</div>
</template>
<script>
	let app = {
		name: 'teraform-step3',
		props: [
			'inputs',
			'content',
			'actions',
			'state',
			'conditions',
			'stepInputs',
			'localState',
			'config',
		],
		data() {
			return {}
		},
		mounted() {
			let $scope = {
				inputs: this.inputs,
				content: this.content,
				actions: this.actions,
				state: this.state,
				conditions: this.conditions
			};
			//console.log('teraform-step3', $scope);
		},
		methods: {}
	};
	export default app;
</script>