<template>
	<div v-if="loaded">
		<div class="c-bank-statements__intro" v-if="config.introduction" v-html="config.introduction" />
		<form v-if="!conditions.displayIllion()"
			  class="c-form c-form--portal js-bank-statement-form"
			  method="post"
			  :action="config.form.action"
			  @submit.prevent="actions.validate($event, actions.handleSubmit, inputs)">
			<input type="hidden" name="g-Recaptcha-Response" />
			<slot />
			<div v-if="state?.result === null">
				<div class="c-form__field">
					<teraform-text :input="inputs.Customer" />
				</div>
				<div class="c-form__field">
					<teraform-radio :input="inputs.ApplicationToggle" />
				</div>
				<div class="c-form__field" v-if="inputs.Application.condition()">
					<teraform-text :input="inputs.Application" />
				</div>
				<div class="c-form__controls">
					<div class="c-form__controls-fallback" v-if="conditions.recaptchaFallbackRequired()">
						<div class="c-form__controls-fallback-error c-form__error" v-html="config.ReCaptchaMessage" />
						<div v-if="actions.initRecaptchaFallback()" id="recaptcha" class="g-recaptcha" data-theme="clean" data-size="normal" :data-sitekey="config.form.recaptcha_fallback"></div>
						<input type="hidden" name="g-Recaptcha-Fallback" value="true" />
					</div>
					<button class="o-btn o-btn--brand o-btn--large" type="submit" :disabled="conditions.isBusy()" :class="{'c-loader--small': conditions.isBusy()}">Submit</button>
				</div>
				<p class="c-form__recaptcha-disclaimer">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
			</div>
		</form>

		<div v-if="conditions.displayIllion()">
			<teraform-illion :input="inputs.Illion" />
			<a :href="config.homeUrl" class="o-btn o-btn--brand o-btn--large">Back to Home</a>
		</div>
	</div>
</template>
<script>
	import $ from 'jquery';
	import { Teraform } from 'Vue/teraform/teraform';
	import { teraformCommon, Patterns } from 'Vue/teraform/common';
	import { recaptcha } from 'App/recaptcha/recaptcha';

	let recaptchaFallbackRequiredStatusCode = 409;
	let initRecaptchaFallbackDone = false;

	let setup = function ($scope, config) {

		let initialState = {
			result: null,
			busy: false,
			complete: false,
			recaptchaInitialised: false,
			recaptchaFallbackRequired: false,
			recaptchaFallbackCompleted: false,
		};

		$scope.state = initialState;

		const selectors = Object.freeze({
			form: '.js-bank-statement-form'
		});

		let logic = {
			illion: {
				getIframeSrc() {
					let base = config.illion.multibankUrl;
					let acct = config.illion.accountCode;
					let application = $scope.inputs.Application.value;
					let customer = $scope.inputs.Customer.value;
					let hasApplication = conditions.hideApplicationField() === false;

					if (base.endsWith('/') === false) {
						base += '/';
					}

					if (hasApplication) {
						// https://scv-test.bankstatements.com.au/INFU-application:1234_customer:1234
						return `${base}${acct}-application:${application}_customer:${customer}`;
					} else {
						// https://scv-test.bankstatements.com.au/INFU-customer:1234
						return `${base}${acct}-customer:${customer}`;
					}
				}
			},
			submit: {
				success(response) {
					if (response.ok) {
						$scope.inputs.Illion.src = logic.illion.getIframeSrc();
						$scope.state.complete = true;
					} else if (response.status === recaptchaFallbackRequiredStatusCode) {
						$scope.state.recaptchaFallbackRequired = true;
					} else {
						alert('There was an issue with your submission. Please talk to to one of our staff members.');
						console.error(Array.from(arguments));
					}
				},
				error() {
					alert('There was an issue with your submission. Please talk to to one of our staff members.');
					console.error(Array.from(arguments));
				}
			},
			recaptcha: {
				init: function (apiKey) {
					recaptcha.init({
						apiKey: apiKey,
						action: 'submit_form',
					});
					$scope.state.recaptchaInitialised = true;
				},
			},
		};

		let ajaxSubmit = teraformCommon.Handlers.AJAX({
			success: logic.submit.success,
			error: logic.submit.error,
			formData: true,
		});

		let actions = {
			handleSubmit: function (event, data, validationSummary) {
				if (validationSummary.errors === 0) {
					recaptcha.refreshToken()
						.then(function () {
							ajaxSubmit($(selectors.form)[0], data, validationSummary);
						})
						.catch(logic.submit.error);
				}
			},
			initRecaptchaFallback: function () {
				let interval = null;
				let element = document.getElementById('recaptcha');
				if (initRecaptchaFallbackDone === false) {

					var setup = function () {
						element = document.getElementById('recaptcha');

						if (element !== null && initRecaptchaFallbackDone === false) {
							initRecaptchaFallbackDone = true;

							var options = {
								sitekey: config.form.recaptcha_fallback,
								callback: function (token) {

									var input = document.querySelector('input[name="g-Recaptcha-Fallback"]');
									input.value = token;

									$scope.localState.recaptchaFallbackCompleted = true;
								},
								'expired-callback': function () {
									$scope.localState.recaptchaFallbackCompleted = false;
								}
							};

							if (window.grecaptcha) {
								window.grecaptcha.ready(function () {
									grecaptcha.render('recaptcha', options);
									window.clearInterval(interval);
								});
							}
						}
					};

					interval = window.setInterval(setup, 100);
				}

				return true;
			}
		};

		let conditions = {
			isBusy: function () {
				return $scope.state.busy === true;
			},
			displayIllion: function () {
				return $scope.state.complete === true;
			},
			hideApplicationField() {
				return $scope.inputs.ApplicationToggle.value === 'Yes';
			},
			recaptchaFallbackRequired: function () {
				var isFallbackRequired = $scope.state.recaptchaFallbackRequired;
				return isFallbackRequired;
			},
			recaptchaFallbackCompleted: function () {
				var isCompleted = $scope.state.recaptchaFallbackCompleted;
				return isCompleted;
			},
		};

		let disabledIfBusy = function () {
			return conditions.isBusy();
		};

		let validation = function (req, regex, length) {
			return {
				required: req,
				pattern: regex,
				maxlength: length
			}
		};

		let inputs = {
			Application: {
				validation: validation(true, /^[0-9]{7,7}$/, 7),
				disabled: disabledIfBusy,
				condition: function () {
					return conditions.hideApplicationField() === false;
				},
				label: 'Application Number',
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Please enter a valid application number'
				}
			},
			ApplicationToggle: {
				validation: {
					required: true
				},
				disabled: disabledIfBusy,
				label: 'Customer Number Only',
				value: 'No',
				values: ['Yes', 'No'],
				errors: {
					errorRequired: 'This field is required',
				}
			},
			Customer: {
				validation: validation(true, /^[0-9]{1,7}$/, 7),
				disabled: disabledIfBusy,
				label: 'Customer Number',
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Please enter a valid customer number'
				}
			},
			Illion: {
				update: function () { },
				appReference: '',
				successMessageHtml: config.success,
				config: config.illion,
				loggingEnabled: false,
			},
		};

		inputs = Teraform.initInputs(inputs);
		$scope.inputs = inputs;

		Teraform.form.init({
			// Required
			$scope: $scope,
			inputs: inputs,

			// Optional
			actions: actions,
			conditions: conditions,
			state: initialState
		});

		logic.recaptcha.init(config.form.recaptcha_key);

		$scope.loaded = true;
	}

	let app = {
		name: 'teraform-bank-statements',
		props: ['config'],
		data() {
			return {
				inputs: null,
				state: null,
				conditions: null,
				actions: null,
				loaded: false
			}
		},
		mounted() {
			setup(this, this.config);
			$('.vue-cloak').removeClass('vue-cloak');

			let recaptchaScript = document.createElement('script');
			recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
			recaptchaScript.setAttribute('id', 'recaptchaScript');
			document.head.appendChild(recaptchaScript);
		},
		methods: {}
	};
	export default app;
</script>