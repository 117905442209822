<template>
	<div v-show="input.condition()">
		<label class="c-form__label" :for="input.htmlId + '-0'" v-if="input.hasLabel()">{{input.label}}<span class="c-form__required-optional" v-if="!(input.validation.required || input.hasCustomRequiredValidation)">&nbsp;(optional)</span></label>
		<label class="c-form__label" :for="input.htmlId + '-0'" v-if="!input.hasLabel() && !(input.validation.required || input.hasCustomRequiredValidation)"><span class="c-form__required-optional">(optional)</span></label>
		<div v-if="loaded">
			<ul class="c-form__radio-list" :id="input.htmlId">
				<li class="c-form__radio-item" v-for="option in input.valuesParsed">
					<label class="o-fake-input__wrapper">
						<input :id="input.htmlId +'-'+ input.valuesParsed.indexOf(option)"
							   :name="input.htmlName"
							   type="radio"
							   class="o-fake-input c-fake-radio"
							   :required="input.validation?.required === true"
							   :value="option.value"
							   :disabled="input.disabled()"
							   :checked="input.proxy[option.value]"
							   @change="input.updateValues(option.value), input.update()"
							   :aria-describedby="input.ariaDescribedby" />
						<span class="c-fake-radio__display" :class="{'has-error': input.error}">{{option.displayValue}}</span>
					</label>
				</li>
			</ul>

			<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
			<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>
		</div>
	</div>
</template>
<script>
	let setup = function ($input) {
		//console.log($input);
		//var ctrl = this;

		//var init = function () {
		//	// 'values' is required
		//	if (typeof ctrl.values === 'undefined') {
		//		throw new Error('Teraform: \'values\' is required for teraform-radio');
		//	}

		//	setDisplayValue();
		//};

		//var setDisplayValue = function () {
		//	// Store current displayValue

		//	var input = ctrl.input;
		//	var value = ctrl.input.value;
		//	var values = ctrl.values;

		//	var matchingValue = values.filter(function (option) {
		//		return option.value === value;
		//	});
		//	matchingValue = matchingValue.length ? matchingValue[0] : null;

		//	if (matchingValue) {
		//		input.displayValue = matchingValue.displayValue;
		//	} else {
		//		input.displayValue = null;
		//	}
		//};

		//var update = function () {
		//	setDisplayValue();
		//};

		if (!!$input.values === false) {
			$input.values = [];
		}

		$input.proxy = {};

		if ($input.value && typeof $input.value === 'string') {
			$input.proxy[$input.value] = true;
		}

		$input.updateValues = function (selected) {

			$input.proxy = {};
			$input.proxy[selected] = true;
			$input.value = selected;

			//console.log({
			//	id: $input.id,
			//	proxy: $input.proxy,
			//	value: $input.value
			//});
		};
	};

	export default {
		name: 'teraform-radio',
		props: ['input'],
		data() {
			return {
				loaded: false
			}
		},
		beforeMount() {
			setup(this.input);
		},
		mounted() {
			this.loaded = true;
			// console.log('teraform-radio', this.input);
		},
		methods: {
		}
	};
</script>