// Define dependencies at the top
var qs = (function() {
	var module = {
		get: function(delimiter) {
			// Returns an object containing the current URL's query string

			// Combines values with the same key, if present, into an array
			// e.g. 'filter-content-type=photos&filter-content-type=videos'
			// will find {'filter-content-type': ['photos','videos']}

			var queryString;
			var query = {};
			var key;
			var val;
			var i;

			if (typeof delimiter !== 'string' || delimiter.length === 0) {
				// If no delimiter or an invalid delimiter was passed in, use null
				delimiter = null;
			}

			queryString = document.location.search.substr(1).split('&');

			for (i = 0; i < queryString.length; i++) {
				queryString[i] = queryString[i].split('=');

				if (queryString[i].length > 1) {
					key = decodeURIComponent(queryString[i][0]);
					val = decodeURIComponent(queryString[i][1]);

					if (val.indexOf(delimiter) !== -1) {
						val = val.split(delimiter);
						val = val.filter(function(el) { return el !== ''; });
					}

					if (query[key]) {
						if (Array.isArray(query[key]) === false) {
							query[key] = [query[key]];
						}

						if (Array.isArray(val)) {
							query[key] = query[key].concat(val);
						} else {
							query[key].push(val);
						}
					} else {
						query[key] = val;
					}
				}
			}

			return query;
		},

		setString: function(query, delimiter) {
			var queryString = [];
			var queryStringParam;

			var key;
			var val;

			var shouldIgnore = function(val) {
				return val === null || val === '' || typeof val === 'undefined';
			};

			for (key in query) {

				queryStringParam = key + '=';

				if (Array.isArray(query[key])) {
					if (shouldIgnore(query[key][1])) {
						continue;
					}

					if (typeof delimiter === 'string') {
						val = query[key].join(delimiter);
					} else {
						val = query[key].join('&' + key + '=');
					}
				} else {
					if (shouldIgnore(query[key])) {
						continue;
					}
					val = query[key];
				}

				queryStringParam += val;

				queryString.push(queryStringParam);
			}

			queryString = '?' + queryString.join('&');

			return queryString;
		},

		set: function(query, delimiter) {
			var queryString = module.setString(query, delimiter);

			document.location = queryString;
		}
	};

	return {
		get: module.get,
		setString: module.setString,
		set: module.set
	};
})();

export { qs };
	