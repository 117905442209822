<template>
<div style="padding: 50px; text-align:center; background: yellow; color: red; font-weight: 900;">
	<p>This is an example VUE component</p>
	<test-component-child/>
</div>
</template>
<script>
	//import { ref } from 'vue';
	//let setText = ref(text)
	export default {
		name: 'test-component',
		data() {
			return {
			}
		},
		mounted() {
		},
		methods: {
		}
	};
</script>