<template>
	<div v-show="input.condition()">
		<label class="c-form__label" :for="input.htmlId" v-if="input.hasLabel()">{{input.label}}<span class="c-form__required-optional" v-if="!(input.validation.required || input.hasCustomRequiredValidation)">&nbsp;(optional)</span></label>
		<label class="c-form__label" :for="input.htmlId" v-if="!input.hasLabel() && !(input.validation.required || input.hasCustomRequiredValidation)"><span class="c-form__required-optional">(optional)</span></label>

		<label class="o-fake-input__wrapper">
			<input :id="input.htmlId"
				   :name="input.htmlName"
				   type="file"
				   class="o-fake-input c-fake-file"
				   :required="input.validation?.required === true"
				   :class="{'has-error': input.error}"
				   :disabled="input.disabled()"
				   @change="input.update()"
				   :aria-describedby="input.ariaDescribedby" />

			<span class="c-fake-file__display" :class="{'has-error': input.error}" aria-hidden="true">
				<span class="c-fake-file__display-value" :class="{'has-value': !!input.value[0]}">{{input.value[0].name || input.placeholder}}</span>
				<span class="o-btn o-btn--small o-btn--brand o-btn--nowrap c-fake-file__display-button">{{input.buttonText}}</span>
			</span>
		</label>

		<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
		<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>
		<span class="c-form__error" v-if="input.errors.errorFileFormat" v-show="input.error === 'file-format'">{{input.errors.errorFileFormat}}</span>
		<span class="c-form__error" v-if="input.errors.errorFileSize" v-show="input.error === 'file-size'">{{input.errors.errorFileSize}}</span>
	</div>
</template>
<script>
	export default {
		name: 'teraform-file',
		props: ['input'],
		data() {
			return {
			}
		},
		mounted() {
		},
		methods: {
		}
	};
</script>