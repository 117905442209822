/* app/ui/sticky/sticky */

import $ from 'jquery';
import { MediaQueries } from 'Util/mediaqueries';
import { throttle } from 'Util/throttle';

var $window = $(window);
var $component;
var stickyHeaderHeight = 0;
var delta = 5;
var componentHeight;
var windowHeight;
var lastScrollTop = 0;
var originalComponentTop = 0;

var MapSticky = {
	init: function() {
		$component = $('.js-sticky-component');
		if (!$component.length) {
			return;
		}
		componentHeight = $component.height();
		windowHeight = $window.height();
		MapSticky._initMediaQueries();
	},

	_initMediaQueries: function() {
		MediaQueries.register([
			{
				// map not shown on small screen
				queries: MediaQueries.queries['megamenu--small'],
				shouldDegrade: false,
				match: function() {

				},
				unmatch: function() {

				}
			}, {
				// bind scroll event on large screen
				queries: MediaQueries.queries['megamenu--large'],
				shouldDegrade: true,
				match: function() {
					$window.on('scroll', throttle(50, function() {
						MapSticky._scrollMap();
					}));
				},
				unmatch: function() {
					$window.off('scroll', MapSticky._scrollMap);
				}
			}
		]);
	},

	_scrollMap: function() {
		var scrollTop = $window.scrollTop();

		//console.log("scroll top: " + scrollTop);
		//console.log("compnent height: " + componentHeight);
		//console.log("window height: " + windowHeight);

		if (scrollTop > stickyHeaderHeight + delta) {
			$component.width($component.width());
			$component.addClass('is-sticky');
			var currentTop = parseInt($component.css('top'), 10);
			//if the component taller than screen height
			if (componentHeight > windowHeight) {
				if (lastScrollTop > scrollTop) {
					//console.log('up');
					//console.log(currentTop);
					if (currentTop < 60) {
						$component.css('top', '+=' + (lastScrollTop - scrollTop));
						console.log("compnent top: " + $component.css('top'));
					} else {
						$component.css('top', '65px');
					}

				} else {
					//console.log('down');
					//console.log(currentTop + componentHeight - windowHeight);
					if (currentTop + componentHeight - windowHeight > 0) {
						$component.css('top', '+=' + (lastScrollTop - scrollTop));
						console.log("compnent top: " + $component.css('top'));
					}
				}
			}
		} else {
			$component.removeClass('is-sticky');
			//$component.width('auto');
			$component.css('top', originalComponentTop);
		}

		lastScrollTop = scrollTop;
	}
};

export { MapSticky };