<template>
	<div v-show="input.condition()">
		<label class="c-form__label" :for="input.htmlId" v-if="input.hasLabel()">{{input.label}}</label>
		<input :id="input.htmlId"
			   :name="input.htmlName"
			   :type="input.type"
			   :placeholder="input.placeholder"
			   class="c-form__input"
			   :data-required="input.validation.required"
			   :maxlength="input.validation?.maxlength"
			   :min="input.validation?.min"
			   :max="input.validation?.max"
			   :class="{'has-error': input.error}"
			   :disabled="input.disabled()"
			   @input="input.update()"
			   v-model="input.value"
			   :aria-describedby="input.ariaDescribedby" />
		<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
		<span class="c-form__error" v-if="input.errors.errorPattern" v-show="input.error === 'pattern'">{{input.errors.errorPattern}}</span>
		<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>
		<span class="c-form__error" v-if="input.errors.errorMin" v-show="input.error === 'min'">{{input.errors.errorMin}}</span>
		<span class="c-form__error" v-if="input.errors.errorMax" v-show="input.error === 'max'">{{input.errors.errorMax}}</span>
	</div>
</template>
<script>
	export default {
		name: 'teraform-text',
		props: ['input'],
		data() {
			return {}
		},
		mounted() {
			if (!!this.input.type === false) {
				this.input.type = 'text';
			}
			//console.log('teraform-text', this.input);
		},
		methods: {
		}
	};
</script>