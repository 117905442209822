<template>
	<form :action="config.form.action" class="c-form c-form--portal js-payment-form" @submit.prevent="actions.validate($event, actions.handleSubmit, inputs)" novalidate>
		<input type="hidden" name="g-Recaptcha-Response" />
		<slot />
		<h3 class="c-form__section-title">{{config.form.content.SectionLabelPaymentDetails}}</h3>
		<div v-if="state?.result === null">
			<div class="c-form__field">
				<teraform-text :input="inputs.FullName" />
			</div>
			<div class="c-form__field">
				<teraform-text :input="inputs.Email" />
			</div>
			<div class="c-form__field">
				<teraform-text :input="inputs.LoanId" />
			</div>
			<div class="c-form__field">
				<teraform-currency :input="inputs.PaymentAmount" />
			</div>
			<div style="display: none;">
				<teraform-text :input="inputs.PageId" />
			</div>
			<div class="c-form__controls">
				<div class="c-form__controls-fallback" v-if="conditions.recaptchaFallbackRequired()">
					<div class="c-form__controls-fallback-error c-form__error" v-html="config.form.content.ReCaptchaMessage" />
					<div v-if="actions.initRecaptchaFallback()" id="recaptcha" class="g-recaptcha" data-theme="clean" data-size="normal" :data-sitekey="config.form.recaptcha_fallback"></div>
					<input type="hidden" name="g-Recaptcha-Fallback" value="true" />
				</div>
				<button class="o-btn o-btn--brand o-btn--large" type="submit" :disabled="conditions.isBusy()" :class="{'c-loader--small': conditions.isBusy()}">Submit</button>
			</div>
		</div>
		<div class="o-body-text" v-if="state?.result === 'error'" v-html="config.form.content.MessageSubmissionFailure" />
		<p class="c-form__recaptcha-disclaimer">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
	</form>
</template>
<script>
	import $ from 'jquery';
	//import { ref, toRaw } from 'vue';
	import { Teraform } from 'Vue/teraform/teraform';
	import { teraformCommon, Patterns } from 'Vue/teraform/common';
	import { recaptcha } from 'App/recaptcha/recaptcha';
	import * as util from 'Util/core';

	let recaptchaFallbackRequiredStatusCode = 409;
	let initRecaptchaFallbackDone = false;

	let setup = function ($scope, config) {

		let initialState = {
			result: null,
			busy: false,
			recaptchaInitialised: false,
			recaptchaFallbackRequired: false,
			recaptchaFallbackCompleted: false,
		};

		//console.log({
		//	method: 'setup',
		//	$scope
		//});

		const selectors = Object.freeze({
			form: '.js-payment-form'
		});

		let logic = {
			submit: {
				recaptchaSubmitHandler: function (e, data, validationSummary) {
					//console.log({
					//	method: 'logic.submit.recaptchaSubmitHandler',
					//	data,
					//	validationSummary
					//});

					recaptcha.refreshToken()
						.then(function () {
							logic.submit.ajaxSubmit($(selectors.form)[0], data, validationSummary);
						})
						.catch(logic.submit.error);
				},
				before: function () {
					$scope.state.busy = true;
				},
				success: function (response) {
					$scope.state.busy = false;
					if (response.status === 200) {
						response.text().then(url => { window.location = url; });
						$scope.state.result = 'success';
					} else if (response.status === recaptchaFallbackRequiredStatusCode) {
						$scope.state.recaptchaFallbackRequired = true;
					} else {
						$scope.state.result = 'error';
						logic.submit._scrollToFormTop();
					}
				},
				error: function () {
					// console.error(Array.from(arguments));
					$scope.state.busy = false;
					$scope.state.result = 'error';
					logic.submit._scrollToFormTop();
				},
				_scrollToFormTop: function () {
					var $form = $(selectors.form);
					util.scrollToElm($form, 100);
				}
			},
			recaptcha: {
				init: function (apiKey) {
					recaptcha.init({
						apiKey: apiKey,
						action: 'submit_payment',
					});
					$scope.state.recaptchaInitialised = true;
				},
			},
		};

		logic.submit.ajaxSubmit = teraformCommon.Handlers.AJAX({
			beforeSubmit: logic.submit.before,
			success: logic.submit.success,
			error: logic.submit.error,
			formData: true,
		});

		let actions = {
			handleSubmit: logic.submit.recaptchaSubmitHandler,
			initRecaptcha: logic.recaptcha.init,
			initRecaptchaFallback: function () {
				let interval = null;
				let element = document.getElementById('recaptcha');
				if (initRecaptchaFallbackDone === false) {

					var setup = function () {
						element = document.getElementById('recaptcha');

						if (element !== null && initRecaptchaFallbackDone === false) {
							initRecaptchaFallbackDone = true;

							var options = {
								sitekey: config.form.recaptcha_fallback,
								callback: function (token) {

									var input = document.querySelector('input[name="g-Recaptcha-Fallback"]');
									input.value = token;

									$scope.localState.recaptchaFallbackCompleted = true;
								},
								'expired-callback': function () {
									$scope.localState.recaptchaFallbackCompleted = false;
								}
							};

							if (window.grecaptcha) {
								window.grecaptcha.ready(function () {
									grecaptcha.render('recaptcha', options);
									window.clearInterval(interval);
								});
							}
						}
					};

					interval = window.setInterval(setup, 100);
				}

				return true;
			}
		};

		let conditions = {
			isBusy: function () {
				return $scope.state.busy === true;
			},
			recaptchaFallbackRequired: function () {
				var isFallbackRequired = $scope.state.recaptchaFallbackRequired;
				return isFallbackRequired;
			},
			recaptchaFallbackCompleted: function () {
				var isCompleted = $scope.state.recaptchaFallbackCompleted;
				return isCompleted;
			},
		};

		let disabledIfBusy = function () {
			return conditions.isBusy();
		};

		let validation = function (req, regex, length) {
			return {
				required: req,
				pattern: regex,
				maxlength: length
			}
		};

		let inputs = {
			FullName: {
				validation: validation(true, Patterns.NAME, 200),
				disabled: disabledIfBusy,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Our system does not support digits or special characters in a name'
				}
			},
			Email: {
				validation: validation(true, Patterns.EMAIL, 255),
				disabled: disabledIfBusy,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Please enter a valid email address'
				}
			},
			LoanId: {
				validation: validation(true, Patterns.LOAN_ID, 15),
				disabled: disabledIfBusy,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'This should be a value at least 7 characters long'
				}
			},
			PaymentAmount: {
				validation: {
					required: true,
					pattern: Patterns.DECIMAL,
					maxlength: 8,
					min: 1
				},
				disabled: disabledIfBusy,
				value: 0,
				min: 1,
				errors: {
					errorRequired: 'Please enter a dollar amount',
					errorMin: 'Please enter a dollar amount',
					errorPattern: 'Please enter a dollar amount'
				}
			},
			PageId: {
				value: config.inputs.PageId.value
			}
		};
		inputs = Teraform.mergeFieldConfig(inputs, config.inputs);
		inputs = Teraform.initInputs(inputs);
		$scope.inputs = inputs;

		$scope.inputs.PaymentAmount.value = 0;

		Teraform.form.init({
			// Required
			$scope: $scope,
			inputs: inputs,

			// Optional
			actions: actions,
			conditions: conditions,
			state: initialState
		});

		logic.recaptcha.init(config.form.recaptcha_key);

		//console.log({
		//	$scope,
		//	config,
		//	inputs,
		//	hasLabel: $scope.inputs.FullName.hasLabel()
		//});
	}

	let app = {
		name: 'teraform-loan-payment',
		props: ['config'],
		data() {
			return {
				inputs: null,
				state: null,
				conditions: null,
				actions: null,
			}
		},
		mounted() {
			setup(this, this.config);
			//console.log({
			//	method:'mounted()',
			//	task: 'teraform-loan-payment',
			//	config: this.config,
			//	fields: toRaw(this.fields),
			//	inputs: core.inputs
			//});

			let recaptchaScript = document.createElement('script');
			recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
			recaptchaScript.setAttribute('id', 'recaptchaScript');
			document.head.appendChild(recaptchaScript);
		},
		methods: {}
	};
	export default app;
</script>