<script setup>
	/*
	@if (Model.Title.HasValue() && Model.Text.HasValue())
	{
		<div class="c-app-form__help-button-wrapper">
			<div class="c-app-form__help-button-sticky-wrapper js-sticky--bottom">
				<button class="c-app-form__help-button js-expand-collapse__toggle" aria-controls="step-@(Model.Step)-help"><span aria-hidden="true">?</span> <span class="c-app-form__help-button-text">Help</span></button>
			</div>
		</div>
		<div class="c-app-form__help-wrapper js-expand-collapse js-app-form__help" id="step-@(Model.Step)-help" data-expand-collapse-use-hidden="true" aria-live="polite">
			<div class="c-app-form__help-sticky-wrapper js-sticky--top js-sticky--bottom">
				<div class="c-app-form__help">

					<div class="c-app-form__help-body t-bg-grad--diag t-bg-grad__text">
						<div class="c-app-form__help-body-bg">
							<div class="c-app-form__help-body-viewport">
								<button class="iconf-close c-app-form__help-close js-expand-collapse__close" aria-label="close"></button>

								<h2 class="c-app-form__help-title">@Model.Title</h2>
								<div class="c-app-form__help-text">@Model.Text</div>
							</div>
						</div>
					</div>
					@if (Model.QuickLinks.HasValue())
					{
						<div class="c-app-form__help-footer">
							<ul class="c-app-form__help-tool-list">
								@foreach (var item in Model.QuickLinks)
								{
									if (item is HelpContentItem help)
									{
										<li class="c-app-form__help-tool">
											<a href="@help.Link.Url" target="_blank" class="c-app-form__help-tool-link">
												<span class="@help.Icon c-app-form__help-tool-icon"></span>
												<h3 class="c-app-form__help-tool-name">
													<span>@help.ShortTitle</span>
												</h3>
											</a>
										</li>
									}
									if (item is OnlineChatItem online)
									{
										<button type="button" class="c-app-form__help-tool-link js-chat-expand">
											<span class="@online.Icon c-app-form__help-tool-icon"></span>
											<h3 class="c-app-form__help-tool-name">
												<span>@online.ShortTitle</span>
											</h3>
										</button>
									}
								}
							</ul>
						</div>
					}
					<div class="c-app-form__help-hide-wrapper">
						<button class="o-btn o-btn--small o-btn--bordered o-btn--outline c-app-form__help-hide js-expand-collapse__close">Hide</button>
					</div>
				</div>
			</div>
		</div>
	}
	*/
</script>
<template>
	<div class="c-app-form__help-button-wrapper" v-if="canDisplay()">
		<div class="c-app-form__help-button-sticky-wrapper js-sticky--bottom">
			<button class="c-app-form__help-button js-expand-collapse__toggle" :aria-controls="'step-' + panel.Step + '-help'"><span aria-hidden="true">?</span> <span class="c-app-form__help-button-text">Help</span></button>
		</div>
	</div>
	<div class="c-app-form__help-wrapper js-expand-collapse js-app-form__help" :id="'step-' + panel.Step + '-help'" data-expand-collapse-use-hidden="true" aria-live="polite" v-if="canDisplay()">
		<div class="c-app-form__help-sticky-wrapper js-sticky--top js-sticky--bottom">
			<div class="c-app-form__help">

				<div class="c-app-form__help-body t-bg-grad--diag t-bg-grad__text">
					<div class="c-app-form__help-body-bg">
						<div class="c-app-form__help-body-viewport">
							<button class="iconf-close c-app-form__help-close js-expand-collapse__close" aria-label="close"></button>

							<h2 class="c-app-form__help-title">{{panel.Title}}</h2>
							<div class="c-app-form__help-text" v-html="panel.Text" />
						</div>
					</div>
				</div>

				<div class="c-app-form__help-footer" v-if="panel.QuickLinks.length > 0">
					<ul class="c-app-form__help-tool-list">
						<li v-for="help in panel.QuickLinks" class="c-app-form__help-tool">
							<div v-if="help.ContentType === 'navigationHelpContentItem'">
								<a :href="help.Link" target="_blank" class="c-app-form__help-tool-link">
									<span :class="help.Icon" class="c-app-form__help-tool-icon"></span>
									<h3 class="c-app-form__help-tool-name">
										<span>{{help.ShortTitle}}</span>
									</h3>
								</a>
							</div>
							<div v-if="help.ContentType === 'onlineChatItem'">
								<button type="button" class="c-app-form__help-tool-link js-chat-expand">
									<span :class="help.Icon" class="c-app-form__help-tool-icon"></span>
									<h3 class="c-app-form__help-tool-name">
										<span>{{help.ShortTitle}}</span>
									</h3>
								</button>
							</div>
						</li>
					</ul>
				</div>
				<div class="c-app-form__help-hide-wrapper">
					<button class="o-btn o-btn--small o-btn--bordered o-btn--outline c-app-form__help-hide js-expand-collapse__close">Hide</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	let app = {
		name: 'teraform-help-panel',
		props: [
			'panel'
		],
		data() {
			return {}
		},
		mounted() {
			// console.log({ this: this });
		},
		methods: {
			canDisplay() {
				return this.panel && this.panel.Title && this.panel.Text;
			}
		}
	};
	export default app;
</script>