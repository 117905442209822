import { ref, toRaw } from 'vue';
import { ErrorTypes } from 'Vue/teraform/rules';

let module = {
	initInputs: function (inputs) {
		let keys = Object.keys(inputs);
		// console.log({ keys, inputs });
		for (let i = 0, l = keys.length; i < l; i++) {
			let key = keys[i];
			let input = inputs[key];

			let defaultValidation = {
				required: false,
				pattern: null,
				custom: function () {
					return true;
				}
			};

			let mergedValidation = { ...defaultValidation, ...input.validation };
			input.validation = mergedValidation;

			//console.log({
			//	validation: input.validation,
			//	defaultValidation,
			//	mergedValidation
			//});

			let defaultErrorMessages = {
				errorRequired: 'This field is required',
				errorPattern: 'The value is invalid',
				errorCustom: 'This field has failed custom validation'
			};

			let mergedErrors = { ...defaultErrorMessages, ...input.errors };
			input.errors = mergedErrors;

			let defaultField = {
				htmlId: key,
				htmlName: key,
				label: null,
				ariaDescribedby: null,
				error: null,
				errors: mergedErrors,
				value: null,
				hasCustomRequiredValidation: false,
				validation: mergedValidation,
				liveValidateEnabled: false,
				disabled: function () { return false; },
				update: function () { },
				condition: function () { return true; }
			};

			let mergedField = { ...defaultField, ...input };

			if (!!mergedField.hasLabel === false) {
				mergedField.hasLabel = function () {
					return mergedField?.label?.length > 0;
				};
			}

			if (mergedField.values && Array.isArray(mergedField.values)) {
				module.components.allowSimpleValues(mergedField);
				//console.log({
				//	mergedField,
				//	values: mergedField.values,
				//	valuesParsed: mergedField.valuesParsed,
				//});
			}

			inputs[key] = mergedField;
		}

		return inputs;
	},
	mergeFieldConfig: function (config, inputs) {
		if (!config) {
			return inputs;
		}

		let keys = Object.keys(inputs);

		for (let i = 0, l = keys.length; i < l; i++) {
			let key = keys[i];
			let configItem = config[key];
			let input = inputs[key];

			let merged = { ...configItem, ...input };

			inputs[key] = merged;

			//console.log({
			//	key,
			//	configItem,
			//	input,
			//	merged
			//});
		}

		//console.log('mergeFieldConfig', inputs);

		return inputs;
	},
	isAllValidInputs: function (fields) {
		let valid = true;
		let inputs = toRaw(fields);
		let keys = Object.keys(inputs);

		for (let i = 0, l = keys.length; i < l; i++) {
			let key = keys[i];
			let input = inputs[key];

			input.error = null;

			if (input.validation.required) {
				let isRequiredValid = input.value !== null;
				valid = valid && isRequiredValid;

				if (isRequiredValid === false) {
					fields[key].error = 'required';
					continue;
				}
			}

			if (input.validation.pattern !== null) {
				console.log({
					ptn: input.validation.pattern,
					val: input.value
				});

				let isPatternValid = !!input.value && input.validation.pattern.test(input.value);
				valid = valid && isPatternValid;

				if (isPatternValid === false) {
					fields[key].error = 'pattern';
					continue;
				}
			}

			if (input.validation.custom) {
				let isCustomValid = input.validation.custom();
				valid = valid && isCustomValid;

				if (isCustomValid === false) {
					fields[key].error = 'custom';
					continue;
				}
			}

			console.log('validate', input);
		}

		return valid;
	},

	// CONVERT
	form: {
		init: function (options) {
			// Check required options
			if (typeof options.$scope === 'undefined') {
				throw new Error('Teraform: $scope is not defined');
			}
			if (typeof options.inputs === 'undefined' || options.inputs.length === 0) {
				throw new Error('Teraform: no inputs are defined');
			}

			var $scope = options.$scope;
			var inputs = options.inputs;

			// Optional
			var actions = options.actions || {};
			var state = options.state || {};
			var conditions = options.conditions || {};

			module.form._initState($scope, state);
			module.form._initActions($scope, actions);
			module.form._initConditions($scope, conditions);
			module.form._initInputs($scope, inputs);
			module.form._initStepInputs($scope, inputs);
		},

		_initState: function ($scope, state) {
			var propName;

			$scope.state = {};

			if (typeof state !== 'undefined') {
				for (propName in state) {
					$scope.state[propName] = state[propName];
				}
			}
		},

		_initInputs: function ($scope, inputs) {
			var inputName;
			var input;

			$scope.inputs = inputs;
			for (inputName in $scope.inputs) {
				input = $scope.inputs[inputName];

				module.form._initInput($scope, input, inputName);
			}
		},

		_initStepInputs: function ($scope) {
			var stepInputs = {};
			var hasSteps = false;

			var inputName;
			var input;
			var step;

			var addInputToStep = function (inputName, step) {
				if (typeof stepInputs[step] === 'undefined') {
					stepInputs[step] = [];
				}

				stepInputs[step].push(inputName);
			};

			var i;

			for (inputName in $scope.inputs) {

				input = $scope.inputs[inputName];
				step = input.step;
				if (typeof step !== 'undefined') {
					hasSteps = true;

					if (Array.isArray(step)) {
						for (i = 0; i < step.length; i++) {
							addInputToStep(inputName, step[i]);
						}
					} else {
						addInputToStep(inputName, step);
					}
				}
			}

			if (hasSteps === true) {
				$scope.stepInputs = toRaw(stepInputs);
				//console.log(toRaw(stepInputs));
			}
		},

		_initInput: function ($scope, input, inputName) {
			var getCondition = input.condition;

			input.error = false;
			input.liveValidateEnabled = false;
			input.name = input.name || inputName;
			input.id = input.id || input.name;

			input.validation = input.validation || {};

			if (getCondition) {
				input.condition = function () {
					var prevCondition = input.prevCondition || false;
					var condition = getCondition();

					return condition;
				};
			} else {
				input.condition = function () {
					return true;
				};
			}
			input.disabled = input.disabled || function () { return false; };

			// Allow the liveValidate method to be accessed from the input
			// so that the input can be created with a component
			input.liveValidate = function () {
				module.form._liveValidate($scope, input);
			};

			// Wrap the input's update method to include live validation
			let originalUpdate = input.update;
			input.update = function () {
				originalUpdate();
				input.liveValidate();
			};
		},

		_initActions: function ($scope, actions) {

			//console.log({
			//	method: '_initActions',
			//	$scope,
			//	actions
			//});

			var propName;

			$scope.actions = {};

			if (typeof actions !== 'undefined') {
				// Check reserved actions
				if (typeof actions.validate !== 'undefined') {
					throw new Error('Teraform: \'validate\' is a reserved action name, please choose a different name.');
				}
				if (typeof actions.submit !== 'undefined') {
					throw new Error('Teraform: \'submit\' is a reserved action name, please choose a different name.');
				}

				for (propName in actions) {
					$scope.actions[propName] = actions[propName];
				}
			}

			$scope.actions.validate = module.form._validate($scope);

			// submit is an alias for validate, kept for backwards compatibility prior to 1.1
			$scope.actions.submit = module.form._validate($scope);

			//console.log({
			//	method: '_initActions.end',
			//	'$scope.actions': $scope.actions,
			//});
		},

		_initConditions: function ($scope, conditions) {
			var propName;

			$scope.conditions = {};

			if (typeof conditions !== 'undefined') {
				for (propName in conditions) {
					$scope.conditions[propName] = conditions[propName];
				}
			}
		},

		_liveValidate: function ($scope, input) {
			if (input.liveValidateEnabled) {
				module.form._validateInput($scope, input);
			}
		},

		_validateForm: function ($scope, inputsToValidate, ignoreConditionsAndDisabled, isSilentCheck) {
			// ignoreConditionsAndDisabled and isSilentCheck are only passed through
			// when external code requests a validation summary

			// If this is a silent check, then inputs' error states won't be updated
			var i;
			var inputName;
			var input;

			var inputErrors = {};

			var summary = {
				inputs: {},
				errors: 0
			};

			if (typeof inputsToValidate === 'undefined') {
				// Validate all inputs by default
				inputsToValidate = Object.keys(toRaw($scope.inputs));
			} else if (!Array.isArray(inputsToValidate)) {
				inputsToValidate = Object.keys(toRaw(inputsToValidate));
			}

			// Two step process in case of interdependent custom validation
			// First, validate all inputs
			for (i = 0; i < inputsToValidate.length; i++) {
				inputName = inputsToValidate[i];
				input = $scope.inputs[inputName];
				inputErrors[inputName] = module.form._validateInput($scope, input, ignoreConditionsAndDisabled, isSilentCheck);
			}

			// Then, build the validation summary
			for (i = 0; i < inputsToValidate.length; i++) {
				inputName = inputsToValidate[i];

				input = $scope.inputs[inputName];
				summary.inputs[inputName] = {
					valid: true,
					id: input.htmlId
				};

				if (inputErrors[inputName]) {
					summary.errors += 1;
					summary.inputs[inputName].valid = false;
					summary.inputs[inputName].errorType = inputErrors[inputName];
				}
			}

			//console.log({
			//	method: '_validateForm',
			//	$scope,
			//	inputsToValidate,
			//	ignoreConditionsAndDisabled,
			//	isSilentCheck,
			//	summary
			//});

			return summary;
		},

		getValidationSummary: function ($scope, inputsToValidate, ignoreConditionsAndDisabled) {
			return module.form._validateForm($scope, inputsToValidate, ignoreConditionsAndDisabled, true);
		},

		_validateInput: function ($scope, input, ignoreConditionsAndDisabled, isSilentCheck) {
			var value = input.value;
			var rules = input.validation;
			var error = null;
			var condition;
			var disabled;

			var customError;

			// If a value is an empty array, it should be treated as falsy
			if (Array.isArray(value) && value.length === 0) {
				value = undefined;
			}

			if (!isSilentCheck) {
				// Once an input has been validated for the first time,
				// enable live validation on that input.
				input.liveValidateEnabled = true;
			}

			if (ignoreConditionsAndDisabled) {
				condition = true;
				disabled = false;
			} else {
				condition = input.condition();
				disabled = input.disabled();
			}

			if (condition === true && disabled === false) {
				// Required
				///////////
				if (rules.required) {
					if (!value && value !== 0) {
						error = ErrorTypes.REQUIRED;
					} else if (value.trim && !value.trim()) {
						// Strings containing only whitespace don't pass this rule
						error = ErrorTypes.REQUIRED;
					}
				}

				// Component
				////////////
				if (error === null && input.componentValidation) {
					customError = input.componentValidation.call(input, value) || ErrorTypes.CUSTOM;

					if (customError !== true) {
						error = customError;
					}
				}

				// Pattern
				//////////
				if (error === null && rules.pattern) {
					if (value && !rules.pattern.test(value)) {
						error = ErrorTypes.PATTERN;
					}
				}

				// Min
				//////
				if (error === null && rules.min) {
					if (value || value === 0) {
						if (typeof value === 'number') {
							if (value < rules.min) {
								error = ErrorTypes.MIN;
							}
						}
					}
				}

				// Max
				//////
				if (error === null && rules.max) {
					if (value || value === 0) {
						if (typeof value === 'number') {
							if (value > rules.max) {
								error = ErrorTypes.MAX;
							}
						}
					}
				}

				// Custom
				/////////
				if (error === null && rules.custom) {
					customError = rules.custom.call(input, value) || ErrorTypes.CUSTOM;

					if (customError !== true) {
						error = customError;
					}
				}
			}

			if (!isSilentCheck) {
				input.error = error;
			}

			//console.log({
			//	method: '_validateInput',
			//	input,
			//	ignoreConditionsAndDisabled,
			//	isSilentCheck,
			//	error
			//});

			return error;
		},

		_validate: function ($scope) {

			return function (e, callback, inputsToValidate) {
				var formData;
				var validationSummary;

				if (typeof callback !== 'undefined') {
					// Do validation
					formData = module.form._exportData($scope);
					validationSummary = module.form._validateForm($scope, inputsToValidate);

					callback(e, formData, validationSummary);
				} else {
					throw new Error('Teraform: A custom callback function to process the validation summary is required');
				}

				//console.log({
				//	method: '_validate',
				//	callback,
				//	inputsToValidate
				//});
			};
		},

		_exportData: function ($scope) {
			var inputName;
			var data = {};
			let inputs = toRaw($scope.inputs);
			for (inputName in inputs) {
				data[inputName] = inputs[inputName].value;
			}

			return data;
		}
	},

	components: {

		allowSimpleValues: function (ctrl) {
			// Some controllers' have a values property, which is
			// expected to be an array of {displayValue, value} objects,
			// but if a string is passed instead interpret that
			// as having the same displayValue and value.

			// Not sure why updating ctrl.values doesn't work here, but
			// using a new valuesParsed property instead does work.

			var i;

			ctrl.valuesParsed = [];
			for (i in ctrl.values) {
				if (typeof ctrl.values[i] === 'string' || typeof ctrl.values[i] === 'number') {
					ctrl.valuesParsed.push({
						displayValue: ctrl.values[i] + '',
						value: ctrl.values[i] + ''
					});
				} else {
					ctrl.valuesParsed.push(ctrl.values[i]);
				}
			}
		},

	}
};

export { module as Teraform };