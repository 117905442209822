<template>
	<div class="c-form__field c-form__field--mid c-form__field-split-h" v-show="input.condition()" :id="input.htmlId">
		<label :for="input.htmlId + 'Day'" class="c-form__label u-1/1" v-if="input.hasLabel()">{{input.label}}<span class="c-form__required-optional" v-if="!(input.validation.required || input.hasCustomRequiredValidation)">&nbsp;(optional)</span></label>
		<label :for="input.htmlId + 'Day'" class="c-form__label u-1/1" v-if="!input.hasLabel() && !(input.validation.required || input.hasCustomRequiredValidation)"><span class="c-form__required-optional">(optional)</span></label>

		<div class="c-form__field c-form__date-short">
			<select :id="input.htmlId + 'Day'"
					:name="input.htmlName + 'Day'"
					class="c-form__input"
					:data-required="input.validation.required"
					:class="{'has-error': input.error}"
					:disabled="input.disabled()"
					v-model="input.day"
					@change="input.updateValues(), input.update()">
				<option v-for="day in input.days">{{day}}</option>
			</select>
		</div>

		<div class="c-form__field c-form__date-long">
			<select :id="input.htmlId + 'Month'"
					:name="input.htmlName + 'Month'"
					class="c-form__input"
					:data-required="input.validation.required"
					:class="{'has-error': input.error}"
					:disabled="input.disabled()"
					v-model="input.month"
					@change="input.updateValues(), input.update()">
				<option v-for="month in input.months" :value="month.value">{{month.displayValue}}</option>
			</select>
		</div>

		<div class="c-form__field c-form__date-short">
			<select :id="input.htmlId + 'Year'"
					:name="input.htmlName + 'Year'"
					class="c-form__input"
					:data-required="input.validation.required"
					:class="{'has-error': input.error}"
					:disabled="input.disabled()"
					v-model="input.year"
					@change="input.updateValues(), input.update()">
				<option v-for="year in input.years">{{year}}</option>
			</select>
		</div>

		<input type="date" :name="input.name" v-model="input.value" style="display: none;" />

		<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
		<span class="c-form__error" v-if="input.errors.errorMin" v-show="input.error === 'min'">{{input.errors.errorMin}}</span>
		<span class="c-form__error" v-if="input.errors.errorInvalid" v-show="input.error === 'invalid'">{{input.errors.errorInvalid}}</span>
		<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>
	</div>
</template>
<script>
	import { ErrorTypes } from 'Vue/teraform/rules';

	const minDefault = 18;
	const maxDefault = 100;

	const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	const ComponentErrorTypes = {
		INVALID: 'invalid',
	};

	let module = {
		_intRange: function (start, end) {
			var range;
			var length;

			start = Math.round(start) || 0;
			end = Math.round(end) || 0;

			length = Math.abs(end - start) + 1;

			range = new Array(length);
			range.fill();

			if (end > start) {
				range = range.map(function (el, i) {
					return start + i;
				});
			} else {
				range = range.map(function (el, i) {
					return start - i;
				});
			}

			return range;
		},

		_getDays: function () {
			var days = module._intRange(1, 31);

			return days;
		},

		_getMonths: function () {
			var months = module._intRange(0, monthNames.length - 1);

			months = months.map(function (el) {
				return {
					displayValue: monthNames[el],
					value: el + ''
				};
			});

			return months;
		},

		_getYears: function (minAge, maxAge) {
			var now = new Date();
			var thisYear = now.getFullYear();
			var minAgeYear = thisYear - minAge;
			var maxAgeYear = thisYear - maxAge;

			var years = module._intRange(minAgeYear, maxAgeYear);

			return years;
		}
	};

	let setup = function ($input) {
		var initialDate;

		// Ensure min and max rules are set, because they are
		// necessary for generating the list of year options
		if (typeof $input.validation.min === 'undefined') {
			$input.validation.min = minDefault;
		}

		if (typeof $input.validation.max === 'undefined') {
			$input.validation.max = maxDefault;
		}

		// Generate the options
		$input.days = module._getDays();
		$input.months = module._getMonths();
		$input.years = module._getYears($input.validation.min, $input.validation.max);

		if ($input.value) {
			if (($input.value instanceof Date) === false) {
				// Allow the value to be initialised from a datestring
				$input.value = new Date($input.value);
			}

			// Initialise parts
			$input.year = $input.value.getFullYear().toString();
			$input.month = $input.value.getMonth().toString();
			$input.monthName = monthNames[$input.month];
			$input.day = $input.value.getDate().toString();
		}

		$input.update();

		$input.updateValues = function () {
			var invalidDate;

			//console.log('update values', $input);

			if ($input.year && $input.month && $input.day) {
				$input.value = new Date($input.year, $input.month, $input.day);

				invalidDate = isNaN($input.value.getDate());

				if (invalidDate) {
					$input.value = undefined;
				}
			} else {
				$input.value = undefined;
			}
		};

		$input.componentValidation = function (value) {
			var rules = $input.validation;

			//console.log('componentValidation', value);

			var now;
			var minAgeDate;

			if (rules.required) {
				if (!value) {
					return ErrorTypes.REQUIRED;
				}
			}

			// Validate that the date is valid
			if (
				value.getDate().toString() !== $input.day ||
				value.getMonth().toString() !== $input.month ||
				value.getFullYear().toString() !== $input.year
			) {
				console.log({
					value,
					day: $input.day,
					month: $input.month,
					year: $input.year
				});
				// Check that the date was valid, e.g. not 30th February
				return ComponentErrorTypes.INVALID;
			}

			if (rules.min) {
				now = new Date();
				minAgeDate = new Date(now.getFullYear() - rules.min, now.getMonth(), now.getDate());

				if (value > minAgeDate) {
					// Check that date matches minimum age
					return ErrorTypes.MIN;
				}
			}

			return true;
		};
	};

	export default {
		name: 'teraform-anniversary',
		props: ['input'],
		data() {
			return {}
		},
		mounted() {
			setup(this.input);
			this.loaded = true;
			//console.log('teraform-anniversary', this.input);
		},
		methods: {
		}
	};
</script>