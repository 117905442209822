<template>
	<div v-if="loaded">
		<form class="c-form js-app-form__confirmation" method="post" :action="config.action"
			  @submit.prevent="actions.validate($event, actions.handleSubmit)"
			  novalidate>
			<teraform-illion :input="inputs.Illion" />
			<slot />
		</form>
	</div>
</template>
<script>
	import $ from 'jquery';
	import { ref, toRaw } from 'vue';
	import { Teraform } from 'Vue/teraform/teraform';
	import { debounce } from 'Util/debounce';
	import { qs } from 'Util/querystring';

	const selectors = {
		appForm: '.js-app-form',
		appFormStep: '.js-app-form__step',
	};

	let setup = function ($scope, config) {

		let initialState = {
			busy: false,
		};

		let logic = {

			illion: {
				update: function () {

				},
				submit: function (value) {
					alert();
				},
			},

		};

		let actions = {
			handleSubmit: function (event, data, validationSummary) {
				event.preventDefault();
			}
		};

		let conditions = {
			disabledIfBusy: function () {
				return $scope.state.busy === true;
			}
		};

		let inputs = {
			CreditSense: {
				disabled: conditions.disabledIfBusy
			},

			Illion: {
				update: logic.illion.update,
				appReference: config.token,
				successMessageHtml: config.success,
				config: config.illion,
			},
		};

		if (config) {
			inputs = Teraform.mergeFieldConfig(config.inputs, inputs);
		}

		inputs = Teraform.initInputs(inputs);

		Teraform.form.init({
			// Required
			$scope: $scope,
			inputs: inputs,
			// Optional
			actions: actions,
			conditions: conditions,
			state: initialState
		});

		$scope.loaded = true;
	};

	let app = {
		name: 'teraform-loan-confirmation',
		props: ['config'],
		data() {
			return {
				inputs: null,
				state: null,
				conditions: null,
				actions: null,
				loaded: false
			}
		},
		mounted() {
			setup(this, this.config);
		},
		methods: {
		}
	};
	export default app;
</script>
