import $ from 'jquery';
import { createApp } from 'vue/dist/vue.esm-bundler.js'

import formModuleLoanApplication from 'Vue/teraform/loan-application/loan-application';

const form = {
	selector: '.vue-teraform-loan-app',
	name: 'teraform-loan-app',
	module: formModuleLoanApplication
};

import formModuleLoanConfirmation from 'Vue/teraform/loan-application/loan-confirmation';

const confirmation = {
	selector: '.vue-teraform-loan-confirmation',
	name: 'teraform-loan-confirmation',
	module: formModuleLoanConfirmation
}

import moduleTooltip from 'Vue/teraform/loan-application/components/tooltip';
import moduleHelpPanel from 'Vue/teraform/loan-application/components/help-panel';
import moduleStep1 from 'Vue/teraform/loan-application/components/Step1';
import moduleStep2 from 'Vue/teraform/loan-application/components/Step2';
import moduleStep3 from 'Vue/teraform/loan-application/components/Step3';
import moduleStep4 from 'Vue/teraform/loan-application/components/Step4';

const components = [{
	name: 'teraform-tooltip',
	module: moduleTooltip
}, {
	name: 'teraform-help-panel',
	module: moduleHelpPanel
}, {
	name: 'teraform-step1',
	module: moduleStep1
}, {
	name: 'teraform-step2',
	module: moduleStep2
}, {
	name: 'teraform-step3',
	module: moduleStep3
}, {
	name: 'teraform-step4',
	module: moduleStep4
}];

let module = {
	init: function (fields) {

		if ($(form.selector).length) {
			const formApp = createApp({});
			formApp.component(form.name, form.module);
			//console.log({
			//	message: 'register form',
			//	name: form.name
			//});
			for (let i = 0; i < components.length; i++) {
				let component = components[i];
				formApp.component(component.name, component.module);
				//console.log({
				//	message: 'register component',
				//	name: component.name,
				//	module: component.module
				//});
			}

			for (let j = 0; j < fields.length; j++) {
				let field = fields[j];
				formApp.component(field.name, field.module);
				//console.log({
				//	message: 'register field',
				//	name: field.name
				//});
			}

			formApp.mount(form.selector);
			//console.log({
			//	message: 'mount form',
			//	name: form.selector
			//});
		}

		if ($(confirmation.selector).length) {
			const confirmApp = createApp({});
			confirmApp.component(confirmation.name, confirmation.module);

			for (let j = 0; j < fields.length; j++) {
				let field = fields[j];
				confirmApp.component(field.name, field.module);
			}

			confirmApp.mount(confirmation.selector);
		}

	}
};

export { module as LoanApplicationLoader };