import $ from 'jquery';
import { modal } from 'App/modal/modal';
import * as Keybinding from 'Util/keybinding';
import { publish, subscribe } from 'Util/pubsub';

var warningModalId = 'modal-session-warning';
var clearModalId = 'modal-session-clear';

var selectors = Object.freeze({
	warningModal: '#' + warningModalId,
	clearModal: '#' + clearModalId,
	page: '.js-session-page'
});

var dataAttributes = Object.freeze({
	delay: 'data-session-timeout-minutes'
});

var warningTimeout = null;
var clearTimeout = null;

var module = {
	init: function() {
		module._initSubscriptions();

		module._initWarningTimeout();
	},

	_initSubscriptions: function() {
		subscribe('/modal/closed', module._clearClearTimeoutOnWarningClosed);
	},

	_initWarningTimeout: function() {
		var $warningModal = document.querySelector(selectors.warningModal);
		var warningDelay;

		if ($warningModal) {
			warningDelay = $warningModal.getAttribute(dataAttributes.delay);

			warningDelay = parseFloat(warningDelay);

			// Convert from minutes to ms
			warningDelay = warningDelay * 60 * 1000;

			if (!isNaN(warningDelay)) {
				warningTimeout = window.setTimeout(module._showWarning, warningDelay);
			}
		}
	},

	_showWarning: function() {
		publish('/modal/open', warningModalId);

		module._initClearTimeout();
	},

	_initClearTimeout: function() {
		var $clearModal = document.querySelector(selectors.clearModal);
		var clearDelay;

		if ($clearModal) {
			clearDelay = $clearModal.getAttribute(dataAttributes.delay);

			clearDelay = parseFloat(clearDelay);

			// Convert from minutes to ms
			clearDelay = clearDelay * 60 * 1000;

			if (!isNaN(clearDelay)) {
				clearTimeout = window.setTimeout(module._showClear, clearDelay);
			}
		}
	},

	_clearClearTimeoutOnWarningClosed: function($modal) {
		if ($modal.id === warningModalId) {
			module._clearClearTimeout();
		}
	},

	_clearClearTimeout: function() {
		if (clearTimeout) {
			window.clearTimeout(clearTimeout);
			clearTimeout = null;
		}
	},

	_showClear: function() {
		publish('/modal/close', warningModalId);
		publish('/modal/open', clearModalId);

		// Prevent closing the modal
		// It's already full screen and has no close button,
		// so the only remaining close method is "Escape"
		Keybinding.unbind('escape', modal.close);

		module._clearPage();
	},

	_clearPage: function() {
		var $page = document.querySelector(selectors.page);
		var $warningModal = document.querySelector(selectors.warningModal);

		$page.remove();
		$warningModal.remove();
	}
};

var sessionWarning = {
	init: module.init
};

export { sessionWarning };