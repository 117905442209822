import $ from 'jquery';
import 'magnific-popup';

const selectors = Object.freeze({
	single: '.js-lightbox-single',
	image: '.js-lightbox-image',
	gallery: '.js-lightbox-gallery',
});

function init() {
	_initSingle();
	_initGallery();
}

function _initSingle() {
	const $lightboxes = $(selectors.single);

	if ($lightboxes.length) {
		$lightboxes.magnificPopup({
			delegate: selectors.image,
			type: 'image'
		});
	}
}

function _initGallery() {
	$(selectors.gallery).each(function() {
		$(this).magnificPopup({
			delegate: selectors.image,
			type: 'image',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
			}
		});
	});
}

export const lightbox = { init };
