import { PromiseLike } from 'Util/PromiseLike';

var recaptchaName = 'g-Recaptcha-Response';

var selectors = Object.freeze({
	recaptcha: 'input[name="' + recaptchaName + '"]',
});

/**
 * @typedef {Object} RecaptchaOptions
 * @property {string} apiKey
 * @property {string} action
 */

/** @type {RecaptchaOptions | null} */
var config = null;

var module = {
	/**
	 * Set the default options for token generation.
	 *
	 * @param {RecaptchaOptions} options
	 */
	init: function (options) {
		config = Object.assign({}, options);
	},

	/**
	 * Regenerate the Recaptcha token and apply it
	 * to each Recaptcha input.
	 *
	 * This is the intended method for getting an
	 * active Recaptcha token.
	 *
	 * By default, uses the options passed to `init`,
	 * but they can be overridden via the `options` arg.
	 *
	 * @param {[RecaptchaOptions]} options
	 *
	 * @return {PromiseLike}
	 */
	refreshToken: function (options) {
		var tokenOptions = options || config;

		return new PromiseLike(function (resolve, reject) {
			if (tokenOptions) {
				if (window.grecaptcha) {
					grecaptcha.enterprise.ready(function () {
						grecaptcha.enterprise.execute(tokenOptions.apiKey, { action: tokenOptions.action })
							.then(function (token) {
								module._applyToken(token);

								resolve(token);
							})
							.catch(reject);
					});
				}
			} else {
				reject(new TypeError('Recaptcha token requires API key and action to refresh'));
			}
		});
	},

	/**
	 * Apply the new token to each Recaptcha input.
	 *
	 * @param  {string} token
	 *
	 * @return {void}
	 */
	_applyToken: function (token) {
		/** @type {NodeListOf<HTMLInputElement>} [description] */
		var $inputs = document.querySelectorAll(selectors.recaptcha);

		/** @type {number} */
		var i;
		/** @type {HTMLInputElement} */
		var $input;

		for (i = 0; i < $inputs.length; i++) {
			$input = $inputs[i];
			$input.value = token;
		}
	},
};

var recaptcha = {
	init: module.init,
	refreshToken: module.refreshToken,

	inputName: recaptchaName,
};

export { recaptcha };