<template>
	<div v-show="input.condition()">
		<label class="c-form__label" :for="input.htmlId" v-if="input.hasLabel()">{{input.label}}<span class="c-form__required-optional" v-if="!(input.validation.required || input.hasCustomRequiredValidation)">&nbsp;(optional)</span></label>
		<label class="c-form__label" :for="input.htmlId" v-if="!input.hasLabel() && !(input.validation.required || input.hasCustomRequiredValidation)"><span class="c-form__required-optional">(optional)</span></label>

		<select :id="input.inputId"
				:name="input.htmlName"
				class="c-form__input"
				:data-required="input.validation.required"
				:class="{'has-error': input.error}"
				:disabled="input.disabled()"
				v-model="input.value"
				@change="input.update()"
				:aria-describedby="input.ariaDescribedby">
			<option v-for="option in input.values" :value="option.value">{{option.displayValue}}</option>
		</select>

		<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
		<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>
	</div>

</template>
<script>
	export default {
		name: 'teraform-select',
		props: ['input'],
		data() {
			return {
			}
		},
		beforeMount() {
			if (this.input.value === null || this.input.value === undefined) {
				this.input.value = '';
			}

			if (!!this.input.values === false) {
				this.input.values = [];
			}
			//console.log('teraform-select', this.input);
		},
		mounted() {
		},
		methods: {
		}
	};
</script>