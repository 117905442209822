<template>
	<form v-if="loaded" @submit.prevent="actions.validate($event, actions.handleSubmit, inputs)" novalidate class="c-form todo-fe" style="padding:20px; width:900px; margin: 0 auto;">
		<slot />
		<div class="c-form__field">
			<teraform-text :input="inputs.FullName" />
		</div>
		<div class="c-form__field">
			<teraform-text :input="inputs.Email" />
		</div>
		<div class="c-form__field">
			<teraform-select :input="inputs.EnquiryReason" />
		</div>
		<div class="c-form__field">
			<teraform-textarea :input="inputs.Message" />
		</div>
		<div class="c-form__field">
			<teraform-date :input="inputs.DueDate" />
		</div>
		<div class="c-form__field">
			<teraform-radio :input="inputs.ResponseOptin" />
		</div>
		<div class="c-form__field">
			<teraform-checkbox :input="inputs.AgreedTerms" />
		</div>
		<div class="c-form__field">
			<teraform-checkbox :input="inputs.MarketingSpam" />
		</div>
		<div class="c-form__field" v-show="conditions.selectedMarketing()">
			<teraform-checkboxlist :input="inputs.MarketingCategories" />
		</div>
		<div class="c-form__field">
			<teraform-anniversary :input="inputs.DateOfBirth" />
		</div>
		<div class="c-form__field">
			<teraform-countdown :input="inputs.LimitedMessage" />
		</div>
		<div class="c-form__field">
			<teraform-illion :input="inputs.Illion" />
		</div>
		<div class="c-form__field">
			<teraform-fileUploaded :input="inputs.Files" />
		</div>
		<div class="c-form__field">
			<teraform-address :input="inputs.Address" />
		</div>
		<button type="submit">Submit</button>
	</form>
</template>
<script>
	import { Teraform } from 'Vue/teraform/teraform';
	import { teraformCommon, Patterns } from 'Vue/teraform/common';
	import { recaptcha } from 'App/recaptcha/recaptcha';

	let setup = function ($scope, config) {

		let initialState = {
			busy: false,
			result: null,
			recaptchaInitialised: false
		};

		let logic = {
			submit: {
				handle: function (data, validationSummary) {
					console.log({
						method: 'logic.submit.handle',
						data,
						validationSummary
					});

					if (validationSummary.errors === 0) {
						console.log('submit success');
					}
					$scope.state.busy = false;
				},
				before: function () {
					$scope.state.busy = true;
				},
				success: function (response) {
					if (response.status === 200) {
						$scope.state.result = 'success';
					} else {
						$scope.state.result = 'error';
					}
				},
				error: function () {
					// console.error(Array.from(arguments));
					$scope.state.busy = false;
					$scope.state.result = 'error';
				},
			},
			recaptcha: {
				init: function (apiKey) {
					recaptcha.init({
						apiKey: apiKey,
						action: 'submit_payment',
					});
					$scope.state.recaptchaInitialised = true;
				},
			}
		};

		let actions = {
			handleSubmit: logic.submit.handle
		};

		let conditions = {
			enquiryIsGeneral: function () {
				return $scope.inputs.EnquiryReason.value === 'general';
			},
			selectedMarketing() {
				return $scope.inputs.MarketingSpam.value === true;
			}
		};

		let inputs = {
			FullName: {
				label: 'Name',
				validation: {
					required: true
				},
				disabled: function () {
					return !conditions.enquiryIsGeneral();
				},
				value: 'Tester'
			},
			Email: {
				label: 'Email',
				validation: {
					required: true,
					pattern: Patterns.EMAIL
				},
				type: 'email'
			},
			EnquiryReason: {
				label: 'Enquiry Type',
				values: [{
					displayValue: 'General Enquiry',
					value: 'general'
				}, {
					displayValue: 'Products',
					value: 'products'
				}, {
					displayValue: 'Complaints',
					value: 'spam'
				}]
			},
			Message: {
				label: 'Message'
			},
			DueDate: {
				label: 'Due Date',
				//value: new Date('2024-04-01'),
				validation: {
					custom() {
						return $scope.inputs.DueDate.valueObj > new Date();
					},
					min: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())
				},
				errors: {
					errorCustom: 'must pick a date in the future'
				}
			},
			ResponseOptin: {
				label: 'Would you like a callback by phone?',
				values: ['Yes', 'No'],
				value: 'No'
			},
			AgreedTerms: {
				label: '<p>Yes, I agree to the <a rel="noopener" href="/key-information/privacy-act-acknowledgement/" target="_blank" title="Privacy Act Acknowledgement">Privacy Act Acknowledgement</a></p>',
				validation: {
					required: true,
					pattern: null,
				}
			},
			MarketingSpam: {
				label: 'I opt-in for marketing communications'
			},
			MarketingCategories: {
				label: 'I am interested in:',
				values: [{
					displayValue: 'Technology',
					value: 'tech'
				}, {
					displayValue: 'SEO',
					value: 'seo'
				}],
				value: ['tech'],
				condition: function () {
					return conditions.selectedMarketing();
				},
				validation: {
					required: true
				},
				errors: {
					errorRequired: 'please select some interests'
				},
				update() {
					//console.log($scope.inputs.MarketingCategories.value);
				}
			},
			DateOfBirth: {
				label: 'Date of Birth',
				//value: new Date('2004-04-01'),
				//value: '2004-04-01',
				validation: {
					required: true
				},
				errors: {
					errorRequired: 'Please enter your date of birth',
					errorInvalid: 'Please enter a valid date',
					errorMin: 'You must be at least 18 years old'
				},
				update() {
					//console.log($scope.inputs.DateOfBirth.value);
				}
			},
			LimitedMessage: {
				label: 'Limited Message',
				placeholder: 'Limited message',
				validation: {
					maxlength: 50
				}
			},
			Illion: {
				label: 'Illion Bank'
			},
			Files: {
				label: 'Files',
				placeholder: "No file selected",
				buttonText: "Select a file",
				uploadApi: "api/loan-app/upload-identity-document",
				allowedFormats: "jpg,jpeg,png,gif,pdf",
				maxSizeMb: "5",
				errors: {
					errorRequired: 'Please upload a file.',
					errorIncomplete: 'Please upload your selected file.',
					errorFileFormat: 'Allowed formats are jpg, jpeg, png, gif, and pdf.',
					errorFileSize: 'The file you have selected is too large. Please select a file smaller than 5 MB.',
					errorUpload: 'Something went wrong, please try again.'
				}
			},
			Address: {
				label: 'Address',
				placeholder: 'Field placeholder search address',
				labelUnit: 'Unit',
				labelStreetNumber: 'Street Number',
				labelStreetName: 'Street Name',
				labelStreetType: 'Street Type',
				labelSuburb: 'Suburb',
				labelCity: 'City',
				labelPostcode: 'Postcode',
				placeholderUnit: 'Unit',
				placeholderStreetNumber: 'Street Number',
				placeholderStreetName: 'Street Name',
				placeholderStreetType: 'Street Type',
				placeholderSuburb: 'Suburb',
				placeholderCity: 'City',
				placeholderPostcode: 'Postcode',
				searchUnavailable: 'Search unavailable',
				errors: {
					errorRequired: 'This field is required',
					errorRequiredPart: 'This field is required',
					errorInvalid: 'Please enter a complete address',
					errorInvalidPart: 'This field is required'
				},
				validation: {
					required: true
				}
			}
		};

		inputs = Teraform.initInputs(inputs);
		if (config) {
			inputs = Teraform.mergeFieldConfig(config.inputs, inputs);
		}

		// console.log({ inputs });

		Teraform.form.init({
			// Required
			$scope: $scope,
			inputs: inputs,

			// Optional
			actions: actions,
			conditions: conditions,
			state: initialState
		});

		$scope.loaded = true;

		logic.recaptcha.init('6LfGThIpAAAAAOotQLF2HnEYcnxS0sR2MtRFL-AM');
	};

	let app = {
		name: 'teraform-contact',
		props: ['config'],
		data() {
			return {
				inputs: null,
				state: null,
				conditions: null,
				actions: null,
				loaded: false,
			}
		},
		mounted() {
			setup(this, this.config);
			//console.log({
			//	task: 'teraform-contact.mounted()',
			//	$scope: this
			//});
		},
		methods: {
		}
	};

	export default app;
</script>