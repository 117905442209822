<template>
	<div v-show="input.condition()">
		<label class="c-form__label" :for="input.htmlId" v-if="input.hasLabel()">{{input.label}}<span class="c-form__required-optional" v-if="!(input.validation.required || input.hasCustomRequiredValidation)">&nbsp;(optional)</span></label>
		<label class="c-form__label" :for="input.htmlId" v-if="!input.hasLabel() && !(input.validation.required || input.hasCustomRequiredValidation)"><span class="c-form__required-optional">(optional)</span></label>
		<textarea :id="input.htmlId"
				  :name="input.htmlName"
				  :placeholder="input.placeholder"
				  class="c-form__input"
				  :data-required="input.validation.required"
				  :maxlength="input.validation.maxlength"
				  :class="{'has-error': input.error}"
				  :disabled="input.disabled()"
				  v-model="input.value"
				  @change="input.update()"
				  :aria-describedby="input.ariaDescribedby"></textarea>
		<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
		<span class="c-form__error" v-if="input.errors.errorPattern" v-show="input.error === 'pattern'">{{input.errors.errorPattern}}</span>
		<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>
	</div>
</template>
<script>
	export default {
		name: 'teraform-textarea',
		props: ['input'],
		data() {
			return {
			}
		},
		mounted() {
			// console.log('teraform-textarea', this.input);
		},
		methods: {
		}
	};
</script>