import $ from 'jquery';
import { Rules, Patterns, ErrorTypes } from 'Vue/teraform/rules';
import * as util from 'Util/core';

/////////////
// HELPERS //
/////////////
var Helpers = Object.freeze({
	SCROLL_TO_FIRST_ERROR: function (validationSummary, $element) {
		var inputName;
		var input;

		var $input;
		var $label;

		$element = ($element && $element.length) ? $($element) : $('html');

		// Assume that inputs are defined in order of appearance
		for (inputName in validationSummary.inputs) {
			input = validationSummary.inputs[inputName];

			if (input.valid === false) {
				$input = $element.find('#' + input.id);
				$label = $element.find('[for="' + input.id + '"]');

				if ($label.filter(':visible').length) {
					util.scrollToElm($label, 20);
				} else if ($input.filter(':visible').length) {
					util.scrollToElm($input, 30);
				} else {
					continue;
				}

				break;
			}
		}
	}
});

//////////////
// HANDLERS //
//////////////
var Handlers = Object.freeze({
	AJAX: function (options) {
		/**
		Example:

		var actions = {
			handleSubmit: teraformCommon.Handlers.AJAX($http, {
				beforeSubmit: logic.submit.before,
				success: logic.submit.success,
				error: logic.submit.error,
				formData: true
			})
		};
		*/

		if (typeof options === 'undefined') {
			throw new Error('Please specify options for AJAX submit handler');
		}
		if (typeof options.success === 'undefined') {
			throw new Error('Please specify a success callback for the submit handler');
		}
		if (typeof options.error === 'undefined') {
			throw new Error('Please specify an error callback for the submit handler');
		}

		var busy = false;

		return function ($form, data, validationSummary) {
			// event.preventDefault();

			if (busy) {
				return;
			}

			//console.log({ $form, data, validationSummary });

			if (validationSummary.errors > 0) {
				Helpers.SCROLL_TO_FIRST_ERROR(validationSummary, $form);
			} else {
				var url = $form.getAttribute('action');

				var post = {
					method: 'POST'
				};

				if (options.formData === true) {
					//post.headers = {
					//	'Content-Type': ''
					//};
					//post.body = new URLSearchParams(new FormData($form));
					post.body = new FormData($form);
				} else {
					post.headers = {
						'Content-Type': 'application/json'
					};
					post.body = JSON.stringify(data);
				}

				if (options.beforeSubmit) {
					// If the beforeSubmit function returns a value,
					// that value will overwrite the data to be posted
					var newData = options.beforeSubmit(post.body);
					if (typeof newData !== 'undefined') {
						post.body = newData;
					}
				}

				busy = true;

				//console.log({
				//	method: 'Handlers.AJAX',
				//	options,
				//	$form,
				//	data,
				//	validationSummary,
				//	post,
				//	url
				//});

				fetch(url, post)
					.then(function () {
						var args = Array.prototype.slice.call(arguments);
						var opts = {
							$form: $form
						};
						args.push(opts);

						options.success.apply(this, args);
						busy = false;
					}).catch(function () {
						var args = Array.prototype.slice.call(arguments);
						var opts = {
							$form: $form
						};
						args.push(opts);

						options.error.apply(this, args);
						busy = false;
					});
			}
		};
	},

	POST: function (event, data, validationSummary) {
		/**
		Example:

		var actions = {
			handleSubmit: teraformCommon.Handlers.POST
		};
		*/

		if (validationSummary.errors > 0) {
			event.preventDefault();

			var $form = event.originalEvent ? event.originalEvent.target : event.target;
			Helpers.SCROLL_TO_FIRST_ERROR(validationSummary, $form);
		}
	},
});

var teraformCommon = {
	Helpers: Helpers,
	Handlers: Handlers,
	Rules: Rules,
	Patterns: Patterns,
	ErrorTypes: ErrorTypes
};

export {
	teraformCommon,
	Helpers,
	Rules,
	Handlers,
	Patterns,
	ErrorTypes,
};