/* app/ui/map/branch-filter */
import $ from 'jquery';
import { subscribe } from 'Util/pubsub';
import Velocity from 'velocity-animate';

var selectors = {
	list: '.js-branch-listing',
	item: '.js-branch-listing-item'
};

var BranchFilter = {
	init: function() {
		subscribe('/map/search', BranchFilter._listingUpdate);
	},

	_listingUpdate: function(data) {
		var branchNames = data.storeName;
		var $list = $(selectors.list);
		var $items = $(selectors.item);

		var i;
		var thisName;

		var $itemsToShow;
		var $item;

		$items.hide();

		$itemsToShow = $items.filter(function(index, item) {
			return branchNames.indexOf($(item).data('branch-name')) !== -1;
		});

		for (i = 0; i < branchNames.length; i++) {
			thisName = branchNames[i];

			$item = $itemsToShow.filter(function(index, item) {
				return $(item).data('branch-name') === thisName;
			});

			$list.append($item);
		}

		Velocity($itemsToShow, 'fadeIn', { duration: 250 });

		setTimeout(BranchFilter._listingItemsEqualize, 500);
	},

	_listingItemsEqualize: function() {
		var equaliseGroup = function() {
			var $group = $(this);
			$group.find('.js-equalise').filter(':visible').matchHeight();
		};
		$('.js-equalise-group').each(equaliseGroup);
	}
};

export { BranchFilter };