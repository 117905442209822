/* app/ui/nav/load */

import $ from 'jquery';
import * as Keybinding from 'Util/keybinding';
import { NavSmall } from 'App/nav/small';
import { HeaderSticky } from 'App/nav/sticky';
import { publish, subscribe } from 'Util/pubsub';

var selectors = {
	header: '.js-header',
	nav: '.js-navigation',
	navItem: '.js-navigation__item',
	navIcon: '.js-navigation__icon',
	navItemMega: '.js-navigation__item--megamenu',
	megamenuHeading: '.js-megamenu__heading',
	navOverlay: '.js-navigation__overlay'
};
var classes = {
	headerUp: 'is-up',
	headerDown: 'is-down'
};

var $nav;

var module = {
	init: function () {
		NavLoad = this;
		module._initElements();
		if ($nav.length) {
			module._initEvents();
		}

		HeaderSticky.init(selectors, classes);
	},

	_initElements: function () {
		$nav = $(selectors.nav);
	},

	_initEvents: function () {
		NavSmall.init($nav, selectors, classes);
		Keybinding.bind('escape', module._closeMenu);
	},

	_closeMenu: function () {
		publish('/nav/close');
	}
};

var NavLoad = {
	init: module.init
};

export { NavLoad };
