<template>
	<div v-show="input.condition()">
		<label class="c-form__label" :for="input.htmlId + '-file'" v-if="input.hasLabel()">{{input.label}}<span class="c-form__required-optional" v-if="!(input.validation.required || input.hasCustomRequiredValidation)">&nbsp;(optional)</span></label>
		<label class="c-form__label" :for="input.htmlId + '-file'" v-if="!input.hasLabel() && !(input.validation.required || input.hasCustomRequiredValidation)"><span class="c-form__required-optional">(optional)</span></label>

		<input :id="input.htmlId"
			   :name="input.htmlName"
			   type="hidden"
			   v-model="input.value"
			   @input="input.update()"
			   :required="input.validation.required"
			   :disabled="input.disabled()" />

		<div v-show="!input.conditions.isClearable()">
			<div class="c-fake-file__upload-layout">
				<label class="o-fake-input__wrapper c-fake-file__upload-input">
					<input :id="input.htmlId + '-file'"
						   type="file"
						   class="o-fake-input c-fake-file"
						   :required="input.validation.required"
						   :class="{'has-error': input.error}"
						   :disabled="input.disabled() || input.conditions.isBusy()"
						   @change="input.actions.validateSelectedFile"
						   :aria-describedby="input.ariaDescribedby" />

					<span class="c-fake-file__display" :class="{'has-error': input.error}" aria-hidden="true">
						<span class="c-fake-file__display-value has-value" v-if="!!input.state.files[0]">{{input.state.files[0].name}}</span>
						<span class="c-fake-file__display-value" v-if="!input.state.files[0]">{{input.placeholder}}</span>
						<span class="o-btn o-btn--small o-btn--brand o-btn--nowrap c-fake-file__display-button">{{input.buttonText}}</span>
					</span>
				</label>

				<button type="button"
						class="o-btn o-btn--small o-btn--supporting o-btn--nowrap c-fake-file__upload-button"
						v-if="input.conditions.isReadyToUpload()"
						@click="input.actions.upload"
						:disabled="input.disabled() || input.conditions.isBusy()">
					Confirm and upload
				</button>
			</div>
		</div>
		<div v-show="input.conditions.isClearable()">
			<span class="c-fake-file__display" :class="{'has-error': input.error}">
				<span class="c-fake-file__display-value has-value">{{input.state.fileName}}</span>
				<button type="button"
						class="o-btn o-btn--small o-btn--bordered o-btn--nowrap c-fake-file__display-button"
						@click="input.actions.clear()"
						:disabled="input.disabled()">
					Clear
				</button>
			</span>
		</div>

		<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
		<span class="c-form__error" v-if="input.errors.errorIncomplete" v-show="input.error === 'incomplete'">{{input.errors.errorIncomplete}}</span>
		<span class="c-form__error" v-if="input.errors.errorUpload" v-show="input.error === 'upload'">{{input.errors.errorUpload}}</span>

		<span class="c-form__error" v-if="input.errors.errorFileFormat" v-show="input.error === 'file-format'">{{input.errors.errorFileFormat}}</span>
		<span class="c-form__error" v-if="input.errors.errorFileSize" v-show="input.error === 'file-size'">{{input.errors.errorFileSize}}</span>
	</div>
</template>
<script>
	import { recaptcha } from 'App/recaptcha/recaptcha';
	import { ErrorTypes } from 'Vue/teraform/rules';
	
	const CustomErrorTypes = {
		UPLOAD: 'upload',
		INCOMPLETE: 'incomplete',
	};

	let setup = function ($input) {

		var fileInputId;

		let app = {
			init: function () {
				fileInputId = $input.htmlId + '-file';

				let i;

				$input.state = {
					files: [],
					fileName: '',
					busy: false,
				};

				$input.update = function () {
					console.log('teraform-fileUploaded update', $input.value);
					app.actions.updateDisplayValue();
				};

				// The uploadApi property is required
				if (typeof $input.uploadApi === 'undefined') {
					throw new Error('Teraform: Unable to initialise file-uploaded component. The `upload-api` attribute is required.');
				}

				// JS config can override 'allowedFormats', if present in validation
				if (typeof $input.allowedFormats === 'undefined') {
					$input.allowedFormats = $input.validation.allowedFormats;
				}

				// If 'allowedFormats' was specified, move it into the validation
				if (typeof $input.allowedFormats !== 'undefined') {
					if (typeof $input.validation.allowedFormats === 'undefined') {
						$input.validation.allowedFormats = $input.allowedFormats.split(',');
					}
					delete $input.allowedFormats;
				}

				// If 'allowedFormats' is specified, ensure each element is lower case
				if ($input.validation.allowedFormats) {
					for (i = 0; i < $input.validation.allowedFormats.length; i++) {
						let lowerCaseVal = $input.validation.allowedFormats[i].toLowerCase();
						$input.validation.allowedFormats[i] = lowerCaseVal;
					}
				}

				// JS config can override 'maxSizeMb', if present in validation
				if (typeof $input.maxSizeMb === 'undefined') {
					$input.maxSizeMb = $input.validation.maxSizeMb;
				}

				// If 'maxSizeMb' was specified, move it into the validation
				if (typeof $input.maxSizeMb !== 'undefined') {
					if (typeof $input.validation.maxSizeMb === 'undefined') {
						$input.validation.maxSizeMb = $input.maxSizeMb;
					}
					delete $input.maxSizeMb;
				}

				// If 'maxSizeMb' was specified, ensure it is a number and convert it to MB
				if ($input.validation.maxSizeMb) {
					// Ensure it is a number
					$input.validation.maxSizeMb = parseFloat($input.validation.maxSizeMb);

					// Convert it to MB
					$input.validation.maxSizeMb = $input.validation.maxSizeMb * 1024 * 1024;
				}

				$input.actions = app.actions;
				$input.conditions = app.conditions;

				// If there's an initial value, make sure the display value is in sync
				app.actions.updateDisplayValue();
			},
			actions: {
				/**
				 * Update `ctrl.state.fileName` to match `ctrl.input.value`
				 *
				 * @return {void}
				 */
				updateDisplayValue: function () {
					$input.state.fileName = app.actions._getFileName($input.value);
				},

				/**
				 * @return {void}
				 */
				upload: function () {
					var error = $input.error;
					if (app.conditions.hasFileError()) {
						console.log('Has file error');
						return;
					}

					/** @type {HTMLInputElement} */
					var $fileInput = document.getElementById(fileInputId);
					var files = $fileInput.files;

					if (files.length) {
						app.actions._postFile(files[0]);
					}
				},

				/**
				 * @param  {File} file
				 *
				 * @return {void}
				 */
				_postFile: function (file) {
					var request = new XMLHttpRequest();
					var formData = new FormData();
					console.log(file);
					formData.append('file', file);

					// Assume Recaptcha module was initialised elsewhere
					recaptcha.refreshToken()
						.then(app.actions._postWithRecaptchaToken(request, formData))
						.catch(app.actions._handleUploadError);
				},

				/**
				 * @param  {XMLHttpRequest} request
				 * @param  {FormData} formData
				 *
				 * @return {Function}
				 */
				_postWithRecaptchaToken: function (request, formData) {
					console.log(request);
					console.log(formData);
					/**
					 * @param  {string} token
					 *
					 * @return {void}
					*/
					return function (token) {
						console.log(token);
						formData.append(recaptcha.htmlName, token);

						request.addEventListener('load', app.actions._handleUploadResponse);
						request.addEventListener('error', app.actions._handleUploadError);
						request.addEventListener('abort', app.actions._handleUploadError);

						request.open('POST', $input.uploadApi);
						request.send(formData);

						$input.state.busy = true;

						//$rootScope.$apply();
					};
				},

				/**
				 * @param  {ProgressEvent} event
				 *
				 * @return {void}
				 */
				_handleUploadResponse: function (event) {
					/** @type {XMLHttpRequest} [description] */
					var request = this;
					console.log(event);
					console.log(request);

					if (request.status >= 200 && request.status < 300) {
						// Success
						app.actions._handleUploadSuccess(request);
					} else {
						// Error
						app.actions._handleUploadError(request);
					}
				},

				/**
				 * @param  {XMLHttpRequest} request
				 *
				 * @return {void}
				 */
				_handleUploadSuccess: function (request) {
					$input.state.busy = false;

					var fileUrl = request.responseText;

					$input.value = fileUrl;
					$input.update();
					//$rootScope.$apply();
				},

				/**
				 * @return {void}
				 */
				_handleUploadError: function () {
					$input.state.busy = false;

					$input.error = CustomErrorTypes.UPLOAD;
					//$rootScope.$apply();
				},

				/**
				 * After it's uploaded, the filename is changed. But we need
				 * to display the initial filename to the user, so this code
				 * undoes the transformation done to it on upload.
				 *
				 * @param  {string} fileUrl The URL of the uploaded file.
				 *
				 * @return {string} The cleaned name of the file.
				 */
				_getFileName: function (fileUrl) {
					// Extract uploaded filename from file URL
					var fileName = (fileUrl || '').replace(/^.+\//, '');

					// Expected filename transformation, see LoanApplicationFormController.UploadIdentityDocument()
					// {DateTime.Now:yyy-MM-ddTHH-mm-ss}_{file?.FileName}

					// If there's an _ in the file, remove everything up to and including the first _
					var cleanedFileName = fileName.replace(/^.*?_/, '');

					return cleanedFileName;
				},

				/**
				 * Run custom validation rules on file input when it's selected,
				 * outside of standard Teraform validation process.
				 *
				 * @return {string | null}
				 */
				validateSelectedFile: function (event) {
					$input.state.files = event.target.files;
					// May need to get the files here as v-model can't be used on file inputs
					var value = $input.state.files;
					var rules = $input.validation;

					var file;
					var size;
					var extension;

					if (value && value.length) {
						file = value[0];

						// File format
						if (rules.allowedFormats) {
							extension = file.name.split('.');
							extension = extension[extension.length - 1].toLowerCase();
							if (rules.allowedFormats.indexOf(extension) === -1) {
								$input.error = ErrorTypes.FILE_FORMAT;
								return;
							}
						}

						// File size
						if (rules.maxSizeMb) {
							if (file.size > rules.maxSizeMb) {
								$input.error = ErrorTypes.FILE_SIZE;
								return;
							}
						}
					}

					$input.error = null;
				},

				/**
				 * Clear the input value so a new file can be uploaded in its place.
				 *
				 * @return {void}
				 */
				clear: function () {
					$input.value = '';
					$input.state.fileName = '';
					$input.files = [];
				}
			},
			conditions: {
				isReadyToUpload: function () {
					var isClearable = app.conditions.isClearable();
					var hasFile = $input.state.files.length > 0;
					var hasFileError = app.conditions.hasFileError();

					return !isClearable && hasFile && !hasFileError;
				},

				hasFileError: function () {
					var error = $input.error;

					var hasFileFormatError = error === ErrorTypes.FILE_FORMAT;
					var hasFileSizeError = error === ErrorTypes.FILE_SIZE;
					var hasFileError = hasFileFormatError || hasFileSizeError;

					return hasFileError;
				},

				isBusy: function () {
					return $input.state.busy;
				},

				isClearable: function () {
					return !!$input.value;
				}
			}
		};

		$input.componentValidation = function (value) {
			// The input must be either clear or complete
			if (app.conditions.isReadyToUpload()) {
				return CustomErrorTypes.INCOMPLETE;
			} else {
				return true;
			}
		};

		app.init();
	};

	export default {
		name: 'teraform-fileUploaded',
		props: ['input'],
		data() {
			return {}
		},
		beforeMount() {
			console.log('teraform-fileUploaded beforeMount', this.input);
			setup(this.input);
		},
		mounted() {
			//console.log('teraform-fileUploaded mounted', this.input);
		},
		methods: {
		}
	};
</script>