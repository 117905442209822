import $ from 'jquery';

var selectors = {
	wrapper: '.js-sticky__wrapper',
	sticky: '.js-sticky, .js-sticky--top, .js-sticky--bottom',
	stickyTop: '.js-sticky, .js-sticky--top',
	stickyBottom: '.js-sticky--bottom'
};

var module = {
	init: function() {
		module._initEvents();
	},

	_initEvents: function() {
		$(window).on('scroll resize sticky-init', module._updateAllPositions);

		$(window).trigger('sticky-init');
	},

	_updateAllPositions: function() {
		var $sticky = $(selectors.sticky);

		$sticky.each(module._updatePosition);
	},

	_updatePosition: function(i, el) {
		var $sticky = $(el);
		var position = $sticky.css('position');

		if (position === 'absolute') {
			if ($sticky.is(selectors.stickyTop)) {
				module._updateStickyTopPosition($sticky);
			}
			if ($sticky.is(selectors.stickyBottom)) {
				module._updateStickyBottomPosition($sticky);
			}
		}
	},

	_updateStickyTopPosition: function($sticky) {
		// Stick to the top of the screen,
		// but don't leave the container

		var $wrapper = module._getWrapper($sticky);

		// Revert position
		$sticky.css('top', '');

		// Stick to top of window
		var stickyTop = $sticky.offset().top;
		var windowTop = $(window).scrollTop();
		var offset = windowTop - stickyTop;
		$sticky.css('top', offset);

		// Ensure it's still within its container
		stickyTop = $sticky.offset().top;
		var stickyHeight = $sticky.height();
		var stickyBottom = stickyTop + stickyHeight;

		var wrapperTop = $wrapper.offset().top;
		var wrapperHeight = $wrapper.height();
		var wrapperBottom = wrapperTop + wrapperHeight;

		if (stickyHeight > wrapperHeight) {
			console.error('Sticky: Sticky element taller than container');
		}

		// Stop it sticking out the bottom
		if (stickyBottom > wrapperBottom) {
			offset -= stickyBottom - wrapperBottom;
			$sticky.css('top', offset);

			stickyTop = $sticky.offset().top;
			stickyBottom = stickyTop + stickyHeight;
		}
		// Stop it sticking out the top
		if (stickyTop < wrapperTop) {
			offset += wrapperTop - stickyTop;
			$sticky.css('top', offset);
		}
	},

	_updateStickyBottomPosition: function($sticky) {
		// Stick to the bottom of the screen,
		// but don't leave the container

		var $wrapper = module._getWrapper($sticky);

		// Revert position
		$sticky.css('bottom', '');

		// Stick to bottom of window
		var stickyTop = $sticky.offset().top;
		var stickyHeight = $sticky.height();
		var stickyBottom = stickyTop + stickyHeight;

		var windowTop = $(window).scrollTop();
		var windowHeight = $(window).height();
		var windowBottom = windowTop + windowHeight;

		var offset = stickyBottom - windowBottom;
		$sticky.css('bottom', offset);

		// Ensure it's still within its container
		stickyTop = $sticky.offset().top;
		stickyHeight = $sticky.height();
		stickyBottom = stickyTop + stickyHeight;

		var wrapperTop = $wrapper.offset().top;
		var wrapperHeight = $wrapper.height();
		var wrapperBottom = wrapperTop + wrapperHeight;

		if (stickyHeight > wrapperHeight) {
			console.error('Sticky: Sticky element taller than container');
		}

		// Stop it sticking out the top
		if (stickyTop < wrapperTop) {
			offset -= wrapperTop - stickyTop;
			$sticky.css('bottom', offset);

			stickyTop = $sticky.offset().top;
			stickyHeight = $sticky.height();
			stickyBottom = stickyTop + stickyHeight;
		}
		// Stop it sticking out the bottom
		if (stickyBottom > wrapperBottom) {
			offset += stickyBottom - wrapperBottom;
			$sticky.css('bottom', offset);
		}
	},

	_getWrapper: function($sticky) {
		var $wrapper = $sticky.closest(selectors.wrapper);

		return $wrapper;
	}
};

var Sticky = {
	init: module.init
};

export { Sticky };