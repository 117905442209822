<template>
	<div v-show="input.condition()">
		<label class="c-form__label" :for="input.htmlId" v-if="input.hasLabel()">{{input.label}}<span class="c-form__required-optional" v-if="!(input.validation.required || input.hasCustomRequiredValidation)">&nbsp;(optional)</span></label>
		<label class="c-form__label" :for="input.htmlId" v-if="!input.hasLabel() && !(input.validation.required || input.hasCustomRequiredValidation)"><span class="c-form__required-optional">(optional)</span></label>
		<div class="c-form__currency-layout">
			<span class="c-form__currency-unit">$</span>
			<input :id="input.htmlId"
				   :name="input.htmlName"
				   type="number"
				   :placeholder="input.placeholder"
				   class="c-form__input c-form__currency-input"
				   :data-required="input.validation.required"
				   :maxlength="input.validation?.maxlength"
				   :min="input.validation?.min"
				   :max="input.validation?.max"
				   :class="{'has-error': input.error}"
				   :disabled="input.disabled()"
				   v-model="input.value"
				   :aria-describedby="input.ariaDescribedby" />
		</div>
		<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
		<span class="c-form__error" v-if="input.errors.errorPattern" v-show="input.error === 'pattern'">{{input.errors.errorPattern}}</span>
		<span class="c-form__error" v-if="input.errors.errorCustom" v-show="input.error === 'custom'">{{input.errors.errorCustom}}</span>
		<span class="c-form__error" v-if="input.errors.errorMin" v-show="input.error === 'min'">{{input.errors.errorMin}}</span>
		<span class="c-form__error" v-if="input.errors.errorMax" v-show="input.error === 'max'">{{input.errors.errorMax}}</span>
	</div>
</template>
<script>
	export default {
		name: 'teraform-currency',
		props: ['input'],
		data() {
			return {}
		},
		mounted() {
			// console.log('teraform-currency', this.input);
		},
		methods: {
		}
	};
</script>