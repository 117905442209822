import { subscribe } from 'Util/pubsub';

var assist = (function(subscribe) {
	var $messageEl;

	var classes = {
		visuallyhidden: 'u-visuallyhidden'
	};

	var events = {
		speak: '/assist/speak'
	};

	var module = {
		_init: function() {
			module._initSubscriptions();
		},

		_initSubscriptions: function() {
			subscribe(events.speak, module.speak);
		},

		speak: function(message) {
			if (typeof $messageEl === 'undefined') {
				$messageEl = module._createMessageEl();
			}

			$messageEl.innerHTML = '';
			window.setTimeout(function() {
				$messageEl.innerHTML = message;
			},
			100);
		},

		_createMessageEl: function() {
			var $el = document.createElement('span');
			$el.classList.add(classes.visuallyhidden);
			$el.setAttribute('aria-live', 'assertive');

			document.body.appendChild($el);

			return $el;
		}
	};

	// Automatically initialise when assist is created
	module._init();

	return {
		speak: module.speak
	};
})(subscribe);

export { assist };