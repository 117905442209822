import $ from 'jquery';
import { createApp } from 'vue/dist/vue.esm-bundler.js'

// ## Teraforms
import { TeraFormLoader } from 'Vue/teraform/loader';
TeraFormLoader.init();

// ## Vue Modules
import testComponentModule from 'Vue/components/test-component';
import testComponentChildModule from 'Vue/components/partials/test-component-child';

// ## Vue css selectors for containers of modules
const selectors = {
	testComponent: '.vue-test-component',
};

// ## Vue element tags for registration
const moduleTags = {
	testComponent: 'test-component',
	testComponentChild: 'test-component-child',
};

let module = {
	init: function () {
		module._initComponents();
		module._initControls();
	},
	_initComponents: function () {

		// Test Component
		const testComponent = createApp({});
		testComponent.component(moduleTags.testComponent, testComponentModule);
		testComponent.component(moduleTags.testComponentChild, testComponentChildModule);
		if ($(selectors.testComponent).length) {
			testComponent.mount(selectors.testComponent);
		}
	},
	_initControls: function () {

		// NB: Controls should have 'data-key' attributes to ensure the Vue apps created are unique

		// Example Control
		//let exampleControls = document.querySelectorAll(selectors.exampleControl);
		//if (exampleControls.length) {
		//	for (let i = 0, l = exampleControls.length; i < l; i++) {
		//		let key = exampleControls[i].getAttribute('data-key');
		//		let component = createApp({});
		//		component.component(moduleTags.exampleControl, exampleControlModule);
		//		component.component(moduleTags.exampleControlChildModule, exampleControlChildModule);
		//		component.mount(`${selectors.exampleControl}[data-key="${key}"]`);
		//	}
		//}
	}
};

module.init();